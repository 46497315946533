import { WF_FETCH, WF_CSV_FETCH } from '../constants/actionTypes';

export const wfGet = path => ({
  type: WF_FETCH,
  method: 'GET',
  path,
});

export const wfPost = (path, body) => {
  return {
    type: WF_FETCH,
    method: 'POST',
    path,
    body,
  };
};

export const wfPut = (path, body) => ({
  type: WF_FETCH,
  method: 'PUT',
  path,
  body,
});

export const wfDelete = (path, body) => ({
  type: WF_FETCH,
  method: 'DELETE',
  path,
  body,
});

export const wfCSVGet = path => ({
  type: WF_CSV_FETCH,
  method: 'GET',
  path,
});
