import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Input as InputUI, Label, Segment } from 'semantic-ui-react';
import DisabledLabel from '../../DisabledLabel/DisabledLabel';
import Input from '../../Input/Input';
import AddToOrdinal from '../AddToOrdinal/AddToOrdinal';
import { connect } from 'react-redux';
import { getUserCanEdit } from 'selectors/user';
import './NetworkMerchantKeyword.css';

class NetworkMerchantKeyword extends Component {
  state = {
    isLoading: true,
  };

  updateNetworkMerchantKeyword = async update => {
    const { upsertNetworkMerchantKeyword, setLastUpdated, networkMerchantKeyword } = this.props;
    try {
      await upsertNetworkMerchantKeyword(networkMerchantKeyword.NetworkMerchantID, {
        KeywordName: networkMerchantKeyword.KeywordName,
        ...update,
      });

      setLastUpdated();
    } catch (err) {
      console.error(err);
    }
  };

  removeNetworkMerchantKeyword = async () => {
    const { deleteNetworkMerchantKeyword, setLastUpdated, networkMerchantKeyword } = this.props;
    try {
      await deleteNetworkMerchantKeyword(networkMerchantKeyword.ID);

      setLastUpdated();
    } catch (error) {
      console.error(error);
    }
  };

  getTestURL = () => {
    const {
      networkMerchantKeyword: { SearchURL, SearchCode },
    } = this.props;
    if (!SearchURL) return '';
    return SearchURL.replace('[[SearchCode]]', SearchCode);
  };

  render() {
    const { networkMerchantKeyword, isOrdinalMode, addToOrdinal, isDisabled, viewOnly, canEdit } = this.props;
    const { Ordinal } = networkMerchantKeyword;
    const testURL = this.getTestURL();
    const isViewOnly = viewOnly ?? !canEdit;
    return (
      <Segment.Group className="network-merchant-keyword">
        <Segment>
          {!isViewOnly && isOrdinalMode && !Ordinal && (
            <AddToOrdinal networkMerchantKeyword={networkMerchantKeyword} addToOrdinal={addToOrdinal} />
          )}
          <DisabledLabel
            isDisabled={networkMerchantKeyword.Disabled}
            handleResourceChange={this.updateNetworkMerchantKeyword}
            titleLabel="Network Merchant Keyword"
            uneditable={isViewOnly}
          />
          <h3>
            <Icon name="cubes" />
            <Icon name="tag" />
            Network Merchant Keyword (ID: {networkMerchantKeyword.ID})
          </h3>
          <div className="inputs">
            <InputUI className="input-field" fluid disabled label="Name" value={networkMerchantKeyword.KeywordName} />
            <Input
              label="Kind"
              value={networkMerchantKeyword.Kind}
              handleResourceChange={this.updateNetworkMerchantKeyword}
              disabled={isDisabled || isViewOnly}
            />
            <Link to={`/merchant-admin/network_merchant/${networkMerchantKeyword.NetworkMerchantID}`}>
              <InputUI
                className="input-field"
                fluid
                disabled
                label={<Label>Network Merchant</Label>}
                value={`${networkMerchantKeyword.NetworkMerchantID} (${networkMerchantKeyword.NetworkMerchantName})`}
              />
            </Link>
            <Input
              label="Rank"
              number
              value={networkMerchantKeyword.Rank}
              handleResourceChange={this.updateNetworkMerchantKeyword}
              disabled={isDisabled || isViewOnly}
            />
            <Input
              label="SearchCode"
              value={networkMerchantKeyword.SearchCode}
              handleResourceChange={this.updateNetworkMerchantKeyword}
              disabled={isDisabled || isViewOnly}
            />
            <a href={testURL} target="_blank" rel="noopener noreferrer">
              <InputUI className="input-field" fluid disabled label={<Label>Test Search</Label>} value={testURL} />
            </a>
            <Input
              label="DestinationURL"
              value={networkMerchantKeyword.DestinationURL}
              handleResourceChange={this.updateNetworkMerchantKeyword}
              disabled={isDisabled || isViewOnly}
            />
          </div>

          {!isViewOnly && (
            <div className="controls">
              <Button onClick={this.removeNetworkMerchantKeyword} negative>
                Remove keyword
              </Button>
            </div>
          )}
        </Segment>
      </Segment.Group>
    );
  }
}

NetworkMerchantKeyword.propTypes = {
  upsertNetworkMerchantKeyword: PropTypes.func.isRequired,
  getNetworkMerchant: PropTypes.func.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  isOrdinalMode: PropTypes.bool.isRequired,
  networkMerchantKeyword: PropTypes.shape({
    ID: PropTypes.number.isRequired,
    KeywordName: PropTypes.string.isRequired,
    Kind: PropTypes.string.isRequired,
    NetworkMerchantID: PropTypes.number.isRequired,
    Disabled: PropTypes.bool.isRequired,
    Rank: PropTypes.number.isRequired,
    SearchCode: PropTypes.string.isRequired,
    SearchURL: PropTypes.string.isRequired,
    DestinationURL: PropTypes.string.isRequired,
    NetworkMerchantName: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    viewOnly: PropTypes.bool,
    canEdit: PropTypes.bool,
  }),
};

const mapStateToProps = state => ({
  canEdit: getUserCanEdit(state),
});

export default connect(mapStateToProps, null)(NetworkMerchantKeyword);
