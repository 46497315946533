import { connect } from 'react-redux';

import {
  getKeywordNetworkMerchants,
  upsertNetworkMerchantKeyword,
  setLastUpdated,
  renameKeyword,
  getNetworkMerchant,
} from '../../actions/merchantAdmin';

import { getLastUpdated } from '../../selectors/merchantAdmin';
import { getUserCanEdit } from 'selectors/user';

import Keyword from './Keyword';

const mapStateToProps = state => ({
  lastUpdated: getLastUpdated(state),
  canEdit: getUserCanEdit(state),
});

const mapActionsToProps = {
  getKeywordNetworkMerchants,
  upsertNetworkMerchantKeyword,
  setLastUpdated,
  getNetworkMerchant,
  renameKeyword,
};

export default connect(mapStateToProps, mapActionsToProps)(Keyword);
