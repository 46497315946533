import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { GET_NETWORKS_REQUEST, Network } from './types';
import api from 'helpers/api';
import { WFResponse } from '../request/types';
import { handleRequest } from '../request/sagas';
import { getNetworksSuccess, getNetworksFailure } from './actions';
import { findErrorMessage } from 'helpers';

function* getNetworks(): SagaIterator {
  try {
    const response: WFResponse<Network[]> = yield (call as any)(handleRequest, api.getNetworks);

    if (response.ok) {
      yield put(getNetworksSuccess({ networks: response.json }));
    } else {
      throw new Error(findErrorMessage(response.json, 'failed to get networks'));
    }
  } catch (error) {
    yield put(getNetworksFailure({ errorMessage: findErrorMessage(error) }));
  }
}

function* watchGetNetworks(): SagaIterator {
  yield takeLatest(GET_NETWORKS_REQUEST, getNetworks);
}

export default [watchGetNetworks];
