import React from 'react';
import { useSelector } from 'react-redux';
import { getUserCanEdit } from 'selectors/user';
import { Accordion, Icon } from 'semantic-ui-react';
import AddImage from '../Image/AddImage/AddImage';
import MerchantImage from '../Image/MerchantImage/MerchantImage';
import { IMAGE_KINDS } from '../../constants';
import { MerchantImageType } from './types';
import './NetworkMerchantOnboardingModal.scss';

interface NetworkMerchantOnboardingImageListProps {
  images: MerchantImageType[];
  isImageVisible: boolean;
  addMerchantImage: (imageDetails: FormData) => void;
  removeMerchantImage: (merchantImageId: number) => void;
  handleImagesOpen: () => void;
}

const NetworkMerchantOnboardingImageList: React.FC<NetworkMerchantOnboardingImageListProps> = ({
  images,
  isImageVisible,
  addMerchantImage,
  removeMerchantImage,
  handleImagesOpen,
}) => {
  const canEdit = useSelector(state => getUserCanEdit(state));
  const featuredImages = images.filter(image => image.Kind === IMAGE_KINDS.FEATURED.NAME);
  const logoImages = images.filter(image => image.Kind === IMAGE_KINDS.LOGO.NAME);
  const logoRectImages = images.filter(image => image.Kind === IMAGE_KINDS.LOGORECT.NAME);

  return (
    <Accordion>
      <Accordion.Title className="header" onClick={handleImagesOpen}>
        <h3 className="input-label">
          Images
          <span> ({images.length})</span>
        </h3>
        <div className="icons-container">
          {canEdit && <AddImage addMerchantImage={addMerchantImage} />}
          <Icon name={isImageVisible ? 'triangle up' : 'triangle down'} />
        </div>
      </Accordion.Title>
      <Accordion.Content active={isImageVisible}>
        <h4>Featured</h4>
        <div className="featured-images">
          {featuredImages.length ? (
            featuredImages.map(image => (
              <MerchantImage
                key={image.ID}
                image={image}
                removeMerchantImage={removeMerchantImage}
                viewOnly={!canEdit}
              />
            ))
          ) : (
            <div>None</div>
          )}
        </div>
        <h4>Logo</h4>
        <div className="logo-images">
          {logoImages.length ? (
            logoImages.map(image => (
              <MerchantImage
                key={image.ID}
                image={image}
                removeMerchantImage={removeMerchantImage}
                viewOnly={!canEdit}
              />
            ))
          ) : (
            <div>None</div>
          )}
        </div>
        <h4>LogoRect</h4>
        <div className="logoRect-images">
          {logoRectImages.length ? (
            logoRectImages.map(image => (
              <MerchantImage
                key={image.ID}
                image={image}
                removeMerchantImage={removeMerchantImage}
                viewOnly={!canEdit}
              />
            ))
          ) : (
            <div>None</div>
          )}
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default NetworkMerchantOnboardingImageList;
