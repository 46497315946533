import _ from 'lodash';

export const wfUserAgent = () => `_-wildlink-admin`;

export const objectKeysToLowerCase = object => {
  const newObject = {};
  for (const key in object) {
    newObject[key.toLowerCase()] = object[key];
  }
  newObject.key = object.ID;
  newObject.resourceid = object.ID;
  newObject.title = '';
  newObject.description = '';
  return newObject;
};

export const toPrettyTime = (dateString, includeTime) => {
  const time = new Date(dateString);
  const date = time.toLocaleDateString();
  const options = { hour: '2-digit', minute: '2-digit' };
  const localTime = time.toLocaleTimeString([], options);
  return includeTime ? `${date} - ${localTime}` : date;
};

export const formatDate = dateString => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

export const camelToSnakeCase = str => {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export const getDifferingProperties = (obj1, obj2) => {
  let differences = [];

  // Iterate over each property in the first object
  Object.keys(obj1).forEach(key => {
    // Check if the value is different in the second object
    if (!_.isEqual(obj1[key], obj2[key])) {
      differences.push(key);
    }
  });

  // Also check for properties in the second object not in the first
  Object.keys(obj2).forEach(key => {
    // Add the property only if it's not already in the list and not in the first object
    if (!obj1.hasOwnProperty(key) && !differences.includes(key)) {
      differences.push(key);
    }
  });

  return differences;
};

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/** Creates config object for Option items in Semantic UI Dropdown components
 *  @param option - (string) Text of the option to be created
 *  */
export function createSemanticUIOption(option) {
  return {
    key: option,
    text: option,
    value: option,
  };
}

/** Prints any object or array fully expanded or expanded to a specified depth.
 *  @param item - (object or array) Value to print.
 *  @param maxDepth - (number: optional) Maximum depth to print expanded. A value of 0 will print only the top layer expanded.
 *  @example
 *  ```js
 *  expandedLog(someObj) // expand all layers
 *  expandedLog(someObj, 0) // expand only top layer
 *  expandedLog(someObj, 1) // expand up to 1 layer *within* the top layer
 *  ```
 *  @see https://stackoverflow.com/a/27610197
 *  */
export function expandedLog(item, maxDepth = 100, depth = 0) {
  if (depth > maxDepth) {
    console.log(item);
    return;
  }
  if (typeof item === 'object' && item !== null) {
    Object.entries(item).forEach(([key, value]) => {
      console.group(key + ' : ' + typeof value);
      expandedLog(value, maxDepth, depth + 1);
      console.groupEnd();
    });
  } else {
    console.log(item);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function findErrorMessage(err, defaultMsg) {
  const errorMessages = [];

  function searchErrorMessages(obj) {
    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        const value = obj[key];
        if (
          typeof value === 'string' &&
          (key.toLowerCase().includes('error') || key.toLowerCase().includes('message'))
        ) {
          errorMessages.push(value);
        } else if (typeof value === 'object') {
          searchErrorMessages(value);
        }
      }
    }
  }

  if (typeof err === 'string') {
    try {
      err = JSON.parse(err);
    } catch {
      // Return the string directly if it's not JSON
      return `${defaultMsg || 'An error has occurred: '}${err}`;
    }
  }
  searchErrorMessages(err);

  if (errorMessages.length > 0) {
    // Return the longest error message
    return errorMessages.reduce((longest, current) => (current.length > longest.length ? current : longest), '');
  } else {
    // Return the default message with JSON stringified original object
    return `${defaultMsg || 'An error has occurred: '}${JSON.stringify(err)}`;
  }
}

// Compares to arrays and returns a boolean value whether or not the two are equal
export const areEqualArrays = (array1, array2) => {
  const set1 = new Set(array1);
  const set2 = new Set(array2);

  // Compare sizes
  if (set1.size !== set2.size) {
    return false;
  }

  // Convert sets to sorted arrays and compare
  const sortedArr1 = [...set1].sort();
  const sortedArr2 = [...set2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

export const findNetworkNameWithID = (networks, networkID) => {
  const foundNetwork = networks.find(network => network.ID === networkID);
  if (!foundNetwork) return 'Unknown Network';

  return foundNetwork.Name;
};

// Returns window size in sm, md, or lg depending on the window width
export const getWindowSize = windowWidth => {
  if (windowWidth >= 700 && windowWidth <= 1280) {
    return 'md';
  } else if (windowWidth < 700) {
    return 'sm';
  } else {
    return 'lg';
  }
};
