import { connect } from 'react-redux';

import { getReconcileJob, getReconcileJobMerchants } from '../../actions/reconciles/reconciles';

import ReconciliationJob from './ReconciliationJob';

const mapActionsToProps = {
  getReconcileJob,
  getReconcileJobMerchants,
};

export default connect(null, mapActionsToProps)(ReconciliationJob);
