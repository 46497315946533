import React from 'react';
import { Form } from 'semantic-ui-react';

const PaginateControl = ({ prevDisabled, nextDisabled, onPrev, onNext, page }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '1rem' }}>
        <b>Page {page}</b>
      </span>
      <Form.Button disabled={prevDisabled} onClick={onPrev}>
        &#8592;
      </Form.Button>
      <Form.Button disabled={nextDisabled} onClick={onNext}>
        &#8594;
      </Form.Button>
    </div>
  );
};

export default PaginateControl;
