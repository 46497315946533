import React from 'react';
import { Button } from 'semantic-ui-react';
import Papa from 'papaparse';
import { OnboardedMerchant } from './types';
import { formatInTimeZone } from 'date-fns-tz';

interface RecentlyOnboardedMerchantsExportProps {
  recentlyOnboardedMerchants: OnboardedMerchant[];
  disabled: boolean;
}

const RecentlyOnboardedMerchantsExport = ({
  recentlyOnboardedMerchants,
  disabled,
}: RecentlyOnboardedMerchantsExportProps) => {
  const downloadCSV = async () => {
    const csvData = recentlyOnboardedMerchants.map((onboardedMerchant: OnboardedMerchant) => {
      const { ID, Name, CreatedDate, Disabled, Score } = onboardedMerchant;

      const formattedOnboardedDate = CreatedDate.split('T')[0] || 'Invalid Date';

      if (formattedOnboardedDate === 'Invalid Date') {
        console.error(`No created date provided for Merchant ID: ${ID}`);
      }

      // headers are created by the objects property name.
      return {
        'Merchant ID': ID,
        'Merchant Name': Name,
        'Onboarded Date': formattedOnboardedDate,
        'Disabled Status': Disabled ? 'Disabled' : 'Enabled',
        Score,
      };
    });
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);

    const todaysDate = formatInTimeZone(new Date(), 'UTC', 'yyyy-MM-dd');
    const fileName = `recently-onboarded-merchants-${todaysDate}`;
    a.download = fileName;
    a.click();
  };

  return (
    <Button onClick={downloadCSV} disabled={disabled}>
      Export
    </Button>
  );
};

export default RecentlyOnboardedMerchantsExport;
