import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setBadAuth } from 'actions';
import { findErrorMessage } from 'helpers';

export function* handleRequest(request: Promise<Response>, ...args: any[]): SagaIterator {
  try {
    // https://github.com/redux-saga/redux-saga/issues/1371
    const response: Response = yield (call as any)(request, ...args);
    if (response.status === 401) {
      yield put(setBadAuth());
    } else {
      // ensure the correct context is used
      const json = yield call([response, response.json]);
      if (!response.ok) {
        throw new Error(findErrorMessage(json));
      }
      return {
        status: response.status,
        ok: response.ok,
        json,
      };
    }
  } catch (error) {
    Promise.reject(error);
  }
}
