import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { checkIsDQContractor } from 'helpers/auth0';
import { Checkbox, Icon, Input, Table } from 'semantic-ui-react';
import Lock from '../Lock/Lock';

const CommissionsRow = ({ commission, handleUpdateCommission, viewOnly }) => {
  const [value, setValue] = useState(commission.DisplayName);

  const canEdit = useSelector(state => state.user.canEdit);
  const isViewOnly = viewOnly ?? !canEdit;
  const { user } = useAuth0();
  const isDQContractor = checkIsDQContractor(user);
  const handleChange = (e, data) => setValue(data.value);
  const handleSubmit = e => {
    if (e.keyCode === 13) {
      const updatedCommission = {
        ...commission,
        DisplayName: value.trim(),
      };
      if (commission.DisplayName !== updatedCommission.DisplayName) {
        handleUpdateCommission(updatedCommission);
      }
    }
  };

  return (
    <Table.Row className={commission.Disabled ? 'disable' : null}>
      {!isViewOnly ? (
        <Table.Cell className="center">
          <Checkbox
            disabled={isDQContractor}
            toggle
            defaultChecked={!commission.Disabled}
            onChange={() =>
              handleUpdateCommission({
                ...commission,
                Disabled: !commission.Disabled,
              })
            }
          ></Checkbox>
        </Table.Cell>
      ) : (
        <Table.Cell className="center">
          <Icon name={commission.Disabled ? 'minus circle' : 'check circle'} />
        </Table.Cell>
      )}
      <Table.Cell>{commission.ID}</Table.Cell>
      <Table.Cell>{commission.Name}</Table.Cell>
      <Table.Cell>{commission.Kind}</Table.Cell>
      <Table.Cell>{commission.Amount}</Table.Cell>
      <Table.Cell selectable>
        {isViewOnly ? (
          value
        ) : (
          <Input transparent fluid onChange={handleChange} onKeyDown={handleSubmit} value={value}></Input>
        )}
        <Lock
          commission={commission}
          locks={commission.Locks}
          name={'DisplayName'}
          handleResourceChange={handleUpdateCommission}
          disabled={isViewOnly}
        />
      </Table.Cell>
      <Table.Cell>{commission.IsDefault}</Table.Cell>
    </Table.Row>
  );
};

export default CommissionsRow;
