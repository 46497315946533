import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import CouponRow from './CouponRow';

const CouponTable = ({ coupons, handleUpdateCoupon, viewOnly }) => {
  const canEdit = useSelector(state => state.user.canEdit);
  const isViewOnly = viewOnly ?? !canEdit;
  return (
    <Table singleLine celled compact striped definition>
      <Table.Header>
        <Table.Row>
          {!isViewOnly && <Table.HeaderCell></Table.HeaderCell>}
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
          <Table.HeaderCell>End Date</Table.HeaderCell>
          <Table.HeaderCell>Code</Table.HeaderCell>
          <Table.HeaderCell>Exclusions</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.HeaderCell>URL</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {coupons.map(c => (
          <CouponRow handleUpdateCoupon={handleUpdateCoupon} key={c.ID} coupon={c} viewOnly={isViewOnly} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default CouponTable;
