import { connect } from 'react-redux';

import {
  upsertNetworkMerchantKeyword,
  setLastUpdated,
  deleteNetworkMerchantKeyword,
  getNetworkMerchant,
} from 'actions/merchantAdmin';

import NetworkMerchantKeyword from './NetworkMerchantKeyword';

const mapActionsToProps = {
  upsertNetworkMerchantKeyword,
  deleteNetworkMerchantKeyword,
  getNetworkMerchant,
  setLastUpdated,
};

export default connect(null, mapActionsToProps)(NetworkMerchantKeyword);
