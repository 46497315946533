import { connect } from 'react-redux';

import {
  getDashboardTableStats,
  getDashboardGraphStats,
  getDashboardLastUpdated,
  getFilterOptions,
  getSalesDashboardFilterItems,
} from '../../actions/salesDashboard/dashboardStats';
import { getMerchant } from 'actions/merchantAdmin';
import SalesDashboardV2 from './SalesDashboardV2';

const mapStateToProps = state => ({
  dashboardStats: state.dashboardStats,
});
const mapActionsToProps = {
  getDashboardTableStats,
  getDashboardGraphStats,
  getDashboardLastUpdated,
  getFilterOptions,
  getSalesDashboardFilterItems,
  getMerchant,
};

export default connect(mapStateToProps, mapActionsToProps)(SalesDashboardV2);
