import { connect } from 'react-redux';

import {
  getNetworkMerchant,
  getNetworkMerchantHistory,
  getNetworkMerchantCommissionRateHistory,
  getNetworkMerchantCountryHistory,
  getNetworkMerchantCommissionHistory,
  getCoupons,
  updateCoupon,
  createCoupon,
  getNetworkMerchantCommissions,
  updateNetworkMerchantCommission,
  getNetworkMerchantKeywords,
  updateNetworkMerchant,
  upsertNetworkMerchantKeyword,
  disableNetworkMerchantKeyword,
  setLastUpdated,
  getMerchant,
} from '../../actions/merchantAdmin';

import { getLastUpdated } from '../../selectors/merchantAdmin';
import { networksSelector } from 'redux/store/network/selectors';
import { getUserCanEdit } from '../../selectors/user';

import NetworkMerchant from './NetworkMerchant';

const mapStateToProps = state => ({
  lastUpdated: getLastUpdated(state),
  networks: networksSelector(state),
  canEdit: getUserCanEdit(state),
});

const mapActionsToProps = {
  getNetworkMerchant,
  getNetworkMerchantHistory,
  getNetworkMerchantCommissionRateHistory,
  getNetworkMerchantCountryHistory,
  getNetworkMerchantCommissionHistory,
  getCoupons,
  updateCoupon,
  createCoupon,
  getNetworkMerchantCommissions,
  updateNetworkMerchantCommission,
  getNetworkMerchantKeywords,
  updateNetworkMerchant,
  upsertNetworkMerchantKeyword,
  disableNetworkMerchantKeyword,
  setLastUpdated,
  getMerchant,
};

export default connect(mapStateToProps, mapActionsToProps)(NetworkMerchant);
