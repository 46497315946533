import { connect } from 'react-redux';

import { getReconcileJobItems } from '../../actions/reconciles/reconciles';

import ReconciliationJobItems from './ReconciliationJobItems';

const mapActionsToProps = {
  getReconcileJobItems,
};

export default connect(null, mapActionsToProps)(ReconciliationJobItems);
