import React, { useState, useEffect } from 'react';
import './Coupons.css';
import { Icon, Modal, Form, Button, Message } from 'semantic-ui-react';
import { CountryCodes } from '../../constants/countryCodes';
import { findErrorMessage } from 'helpers';

const AddCoupon = ({ createCoupon, nmID, setLastUpdated }) => {
  const [formData, setFormData] = useState({
    networkMerchantID: nmID,
    source: 'ADMIN',
    xid: '',
    name: '',
    code: '',
    description: '',
    exclusions: '',
    countries: [],
    startDate: '',
    endDate: '',
    url: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newSubmission, triggerNewSubmission] = useState(false);

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (errorMessage === '') {
      setFormData({
        networkMerchantID: nmID,
        source: 'ADMIN',
        xid: '',
        name: '',
        code: '',
        description: '',
        exclusions: '',
        countries: [],
        startDate: '',
        endDate: '',
        url: '',
      });
    }
  }, [errorMessage, newSubmission, nmID]);

  const handleSubmit = async () => {
    setErrorMessage('');
    setIsLoading(true);

    try {
      await createCoupon(formData);
    } catch (e) {
      setErrorMessage(findErrorMessage(e));
    }
    triggerNewSubmission(!newSubmission);
    setIsLoading(false);
    setLastUpdated();
  };

  return (
    <Modal trigger={<Icon name="add" size="large" className="add-coupon" />} size="small">
      <Modal.Header>
        <Icon name="ticket" size="large" /> Add Coupon
      </Modal.Header>
      <Modal.Content>
        <Form error={!!errorMessage} className="coupon-form" autoComplete="off" onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              onChange={handleChange}
              disabled
              required
              label="Network Merchant ID"
              name="nmID"
              value={formData.networkMerchantID}
            />
            <Form.Input onChange={handleChange} required label="XID" name="xid" value={formData.xid} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input onChange={handleChange} label="Name" name="name" value={formData.name} />
            <Form.Input onChange={handleChange} label="Code" name="code" value={formData.code} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input onChange={handleChange} label="Description" name="description" value={formData.description} />
            <Form.Input onChange={handleChange} label="Exclusions" name="exclusions" value={formData.exclusions} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input onChange={handleChange} label="URL" name="url" value={formData.url} />
            <Form.Dropdown
              onChange={handleChange}
              multiple
              search
              selection
              clearable
              label="Countries"
              name="countries"
              options={CountryCodes}
              value={formData.countries}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              onChange={handleChange}
              required
              label="Start Date"
              name="startDate"
              type="date"
              value={formData.startDate}
            />
            <Form.Input onChange={handleChange} label="End Date" name="endDate" type="date" value={formData.endDate} />
          </Form.Group>
          <div>
            <Button loading={isLoading} color="green">
              {isLoading ? 'Loading' : 'Add'}
            </Button>
            <Message error color="red">
              {errorMessage}
            </Message>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default AddCoupon;
