import React, { useEffect, useState } from 'react';
import { Loader, Dimmer, Segment, Checkbox, Icon } from 'semantic-ui-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { allNumbersAreZero, generateNumberAbbreviationLabel, getUniqueObjectsByProps } from './helpers/index';
import { graphColors } from '../../pages/SalesDashboardV2/Constants';
import { Line } from 'react-chartjs-2';
import { SalesDashFormState, SalesDashChartMetric, SalesDashStatsData, SalesDashDataset } from './types';
import { TooltipItem, LegendItem } from 'chart.js';

const isMobile = window.innerWidth <= 800;
const MAX_LEGENDS = isMobile ? 10 : 30;

interface SalesDashChartProps {
  formState: SalesDashFormState;
  searchURI: string;
  chartMetric: SalesDashChartMetric;
  setError: (error: string) => void;
  getDashboardGraphStats: (searchURI: string) => Promise<SalesDashStatsData[]>;
}

const StatsChart = ({ formState, searchURI, chartMetric, setError, getDashboardGraphStats }: SalesDashChartProps) => {
  const { groupBy } = formState;

  const [chartData, setChartData] = useState<SalesDashStatsData[]>([]);
  const [isStackedGraph, setIsStackedGraph] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  const yAxisLabelsArray: number[] = [];
  const lineGraphToolTipCallbacks = {
    label: (tooltipItem: TooltipItem<any>) => tooltipItem.dataset.label,
    // Places the values below the label in the tooltip
    afterBody: (tooltipItem: TooltipItem<any>[]) => {
      let value = tooltipItem[0].formattedValue;
      if (tooltipItem[0].dataset.chartMetric.unit === '$') {
        value = '$' + value;
      }
      return value;
    },
  };

  const stackedGraphToolTipCallbacks = {
    // Empty label is required to prevent from displaying the default label value
    label: () => '',
    // If there are more than 10 labels, limit to 10 per tooltip
    beforeBody: (tooltipItems: TooltipItem<any>[]) => {
      if (tooltipItems.length > 10) {
        tooltipItems = tooltipItems.slice(0, 10);
      }
      return tooltipItems.map(item => item.dataset.label);
    },
    // Truncate labels in a tooltip and show that there are "+n more" labels that are not displayed
    afterBody: (tooltipItem: TooltipItem<any>[]) => {
      if (tooltipItem.length > 10) {
        return `+${tooltipItem.length - 10} more`;
      }
    },
    // Display values with proper units if needed
    afterFooter: (tooltipItem: TooltipItem<any>[]) => {
      if (!tooltipItem.length) return;
      let value = tooltipItem[0].formattedValue;
      if (tooltipItem[0].dataset.chartMetric.unit === '$') {
        value = '$' + value;
      }
      return value;
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    layout: {
      padding: 20,
    },
    plugins: {
      legend: {
        position: isStackedGraph ? 'top' : 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
          boxWidth: 10,
          boxHeight: 10,
          // Limit number of legends for stacked graph
          filter: (item: LegendItem) => {
            if (!isStackedGraph) return item;
            if (typeof item.datasetIndex !== 'undefined' && item.datasetIndex < MAX_LEGENDS) return item;
          },
        },
        reverse: isStackedGraph,
      },
      tooltip: {
        yAlign: 'bottom',
        callbacks: isStackedGraph ? stackedGraphToolTipCallbacks : lineGraphToolTipCallbacks,
      },
    },
    scales: {
      y: {
        stacked: isStackedGraph,
        // Shortens labels by using k for 1,000 and m for 1,000,000
        ticks: {
          callback: (tickValue: number) => {
            yAxisLabelsArray.push(tickValue);
            const maxValue = Math.max(...yAxisLabelsArray);
            const maxValueLength = maxValue.toString().split('.')[0].length;
            return generateNumberAbbreviationLabel(tickValue, maxValueLength);
          },
        },
      },
    },
  };

  // Adding spacing between legends and chart
  const legendMargin = {
    id: 'legendMargin',
    beforeInit: function (chart: any) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        if (isMobile) {
          return (this.height += 10);
        } else {
          return (this.height += 40);
        }
      };
    },
  };

  const getGraphStats = async () => {
    setIsLoading(true);
    try {
      const res = await getDashboardGraphStats(searchURI);
      setChartData(res);
      setError('');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }
  };

  const transformStats = () => {
    const chartItems = getUniqueObjectsByProps(chartData, ['ID', 'Name']);
    let chartLabels: string[] = [];
    let dataSets: SalesDashDataset[] = [];
    let transformedStats = {};

    // Creates an array with unique date labels
    for (let i = 0; i < chartData?.length; i++) {
      if (!chartLabels.includes(chartData[i].Date)) {
        chartLabels.push(chartData[i].Date);
      }
    }

    if (groupBy === 'date') {
      const chartDataSets = chartData?.map((item: SalesDashStatsData) => item[chartMetric.key]);

      dataSets = [
        {
          id: 0,
          label: 'Date',
          data: chartDataSets as number[], //chartDataSets is initially string or number but when we sort we convert all data to numbers and ultimately data will be in an array of numbers
          backgroundColor: graphColors[0][0],
          borderColor: graphColors[0][0],
          tension: isStackedGraph ? 0 : 0.35,
          fill: isStackedGraph,
          chartMetric,
          order: 0,
        },
      ];

      transformedStats = {
        labels: chartLabels,
        datasets: dataSets,
      };

      return transformedStats;
    } else {
      const tension = isStackedGraph ? 0 : 0.35;
      dataSets = chartItems.map(chartItem => {
        return {
          id: chartItem.ID,
          label: chartItem.Name,
          data: [],
          backgroundColor: '',
          borderColor: '',
          fill: isStackedGraph,
          tension,
          chartMetric,
          order: 1,
          borderWidth: 3,
        };
      });

      if (chartData) {
        // Create a date index map for quick access
        const dateIndexMap = new Map();
        chartLabels.forEach((label, index) => {
          dateIndexMap.set(label, index);
        });

        // Transform dataSets into a map for quick access
        const dataSetsMap = new Map();
        dataSets.forEach(dataSet => {
          dataSetsMap.set(dataSet.id, dataSet);
        });

        chartData.forEach((stat: SalesDashStatsData) => {
          const dateIndex = dateIndexMap.get(stat.Date);
          const foundApp = dataSetsMap.get(stat.ID);

          if (foundApp) {
            foundApp.data[dateIndex] = Number(stat[chartMetric.key]);
          }
        });

        // Fill empty indexes with 0
        dataSets.forEach((dataSet, index) => {
          for (let i = 0; i < chartLabels.length; i++) {
            if (!dataSet.data[i]) {
              dataSet.data[i] = 0;
            }
          }
          // If data doesn't hold valid value, remove the corresponding dataSet to prevent making a graph for it
          if (allNumbersAreZero(dataSet.data)) {
            dataSets.splice(index, 1);
          }
        });
      }

      // Sort dataSets by sum of values in descending order
      dataSets = dataSets.sort((a: SalesDashDataset, b: SalesDashDataset): number => {
        const aSum = a.data.reduce((a, b) => Number(a) + Number(b), 0);
        const bSum = b.data.reduce((a, b) => Number(a) + Number(b), 0);

        return bSum - aSum;
      });

      // Limit to show top 10 dataSets only for line chart
      if (!isStackedGraph) {
        dataSets = dataSets.slice(0, 10);
      }

      // Returns index for the graphColors depending on length of dataSets
      const getColorIndex = (index: number) => {
        const quotient = Math.floor(dataSets.length / 10);
        const colorIndexArray = Array.from({ length: 10 }, (_, i) => Array(quotient).fill(i)).flat();
        const remainder = dataSets.length % 10;
        for (let i = 0; i < remainder; i++) {
          colorIndexArray.push(9);
        }
        return colorIndexArray[index];
      };

      // Assign a border color to each data set
      dataSets.map((dataSet, index) => {
        const orderIndex = isStackedGraph ? dataSets.length - 1 - index : index + 1;
        dataSet.order = orderIndex;
        dataSet.backgroundColor = isStackedGraph ? graphColors[getColorIndex(index)][1] : graphColors[index][0];
        dataSet.borderColor = isStackedGraph ? graphColors[getColorIndex(index)][0] : graphColors[index][0];
      });
      transformedStats = {
        labels: chartLabels,
        datasets: dataSets,
      };

      return transformedStats;
    }
  };

  useEffect(() => {
    getGraphStats();
  }, [formState]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }}>
        <Icon
          className="chart line chart-icon"
          size="large"
          title="Line Graph"
          alt="Line Graph"
          style={{ margin: '0 10px' }}
        />
        <Checkbox
          toggle
          checked={isStackedGraph}
          onClick={() => setIsStackedGraph(!isStackedGraph)}
          style={{ margin: 0 }}
        />
        <Icon
          className="chart area"
          size="large"
          title="Stacked Area Graph"
          alt="Stacked Area Graph"
          style={{ margin: '0 10px' }}
        />
      </div>
      {isLoading ? (
        <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        </Segment>
      ) : (
        // @ts-ignore
        // Types get confused because we use two different graph types.
        <Line options={options} data={transformStats()} plugins={[legendMargin]} />
      )}
    </>
  );
};

export default StatsChart;
