/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input as InputUI } from 'semantic-ui-react';

import './Input.css';

const Input = ({ label, value: initialValue = '', handleResourceChange, number, disabled }) => {
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setHasChanged(value != initialValue);
    if (hasError) {
      setHasError(false);
    }
  }, [value, initialValue, hasError]);

  const handleChange = (e, data) => setValue(data.value);

  const handleSubmit = async e => {
    const updateName = typeof label !== 'string' ? label.props.label : label;
    // only do work on 'enter'
    if (e.keyCode === 13) {
      const trimmedValue = e.target.value.trim();
      setValue(trimmedValue);
      if (trimmedValue !== initialValue) {
        const updatedValue = number ? parseInt(trimmedValue, 10) : trimmedValue;
        setIsLoading(true);
        setHasChanged(false);
        const update = { [updateName]: updatedValue };
        try {
          await handleResourceChange(update);
        } catch (error) {
          setHasError(true);
          setHasChanged(true);
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <InputUI
      label={label}
      fluid
      loading={isLoading}
      error={hasError}
      value={value}
      onKeyDown={handleSubmit}
      onChange={handleChange}
      icon={hasChanged ? 'warning sign' : null}
      disabled={disabled}
    />
  );
};

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleResourceChange: PropTypes.func.isRequired,
  number: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Input;
