import get from 'lodash/get';

import { Network, NetworkState } from './types';
import { RootState } from 'store';

export const networksSelector = (state: RootState): NetworkState['networks'] => {
  return get(state, `merchantAdmin.network.networks`, null);
};

export const networkSelector = (state: RootState, networkId: number): Network | null => {
  return get(state, `merchantAdmin.network.networks.${networkId}`, null);
};
