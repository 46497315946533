import { request } from 'helpers/api';
import React, { FC, useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDevice } from 'actions';
import { Grid, Search as SearchUI } from 'semantic-ui-react';
import SearchResult from 'components/Search/SearchResult';
import { debounce } from 'lodash';

const SearchV2: FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<any>([]);

  const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
  const history = useHistory();

  const objectKeysToLowerCase = (object: Record<string, any>) => {
    let newObject: Record<string, any> = {};
    for (let key in object) {
      newObject[key.toLowerCase()] = object[key];
    }
    newObject.key = object.ID;
    newObject.resourceid = object.ID;
    newObject.title = '';
    newObject.description = '';
    return newObject;
  };

  const isKeywordTargets = useCallback(() => {
    return history.location.pathname.startsWith('/keyword-targets');
  }, [history]);

  const formatSearchResults = useCallback(
    (searchResults: any[]) => {
      if (!searchResults?.length) return [];

      const formatted = searchResults.map(searchResult => objectKeysToLowerCase(searchResult));

      if (!isKeywordTargets()) return formatted;
      return formatted.filter(x => x.type === 'keyword' && domainRegex.test(x.value));
    },
    [domainRegex, isKeywordTargets],
  );

  const handleResultSelect = useCallback(
    (event, { result }) => {
      let path = null;
      if (isKeywordTargets()) {
        path = `/keyword-targets/${result.resourceid}`;
      } else {
        path = `/merchant-admin/${result.type}/${result.resourceid}`;
      }

      history.push(path);
    },
    [history, isKeywordTargets],
  );

  const onSetSearchQuery = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      setSearchQuery(event.target.value);
    }, 500),
    [],
  );

  const render = useCallback(() => {
    return (
      <Grid.Column>
        <SearchUI
          className="search"
          fluid
          input={{
            fluid: true,
            placeholder: 'Search...',
            iconPosition: 'left',
          }}
          loading={isLoading}
          onResultSelect={handleResultSelect}
          onSearchChange={(event: any): void => {
            event.persist();
            setInputValue(event.target.value);
            onSetSearchQuery(event);
          }}
          results={formatSearchResults(searchResults)}
          value={inputValue}
          //@ts-ignore
          resultRenderer={SearchResult}
          open={searchResults.length > 0}
        />
      </Grid.Column>
    );
  }, [searchResults, inputValue, isLoading, formatSearchResults, handleResultSelect, onSetSearchQuery]);

  useEffect(() => {
    const getSearchResults = async () => {
      setIsLoading(true);
      try {
        const searchResults = await request('GET', `/api/search?q=${encodeURIComponent(searchQuery)}`);
        const searchResultsJSON = await searchResults.json();
        setSearchResults(searchResultsJSON);
      } catch (error) {
        if (error === 'Missing device token') {
          dispatch(getDevice());
        } else {
          console.error(error);
        }
      }
    };

    if (!searchQuery) {
      setSearchResults([]);
    } else {
      getSearchResults();
      setIsLoading(false);
    }
  }, [searchQuery]);

  return <Grid>{render()}</Grid>;
};

export default SearchV2;
