import { connect } from 'react-redux';

import { getNetworkMerchants, getNetworks } from 'actions/networkMerchantOnboarding/networkMerchantOnboarding';
import {
  getNetworkMerchant,
  getNetworkMerchantCommissionHistory,
  getNetworkMerchantCommissions,
  updateNetworkMerchantCommission,
  getNetworkMerchantKeywords,
  updateNetworkMerchant,
  upsertNetworkMerchantKeyword,
  getMerchant,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
} from 'actions/merchantAdmin';

import NetworkMerchantOnboarding from './NetworkMerchantOnboarding';

const mapActionsToProps = {
  getNetworkMerchants,
  getNetworks,
  getNetworkMerchant,
  updateNetworkMerchant,
  getNetworkMerchantCommissions,
  updateNetworkMerchantCommission,
  getNetworkMerchantCommissionHistory,
  getNetworkMerchantKeywords,
  upsertNetworkMerchantKeyword,
  getMerchant,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
};

export default connect(null, mapActionsToProps)(NetworkMerchantOnboarding);
