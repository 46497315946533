import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';
import './TruncatedTableCell.scss';

type PropsWithChildren = {
  children?: React.ReactNode;
};

const TruncatedTableCell = ({ children }: PropsWithChildren) => {
  const [isTruncated, setIsTruncated] = useState(true);

  return (
    <Table.Cell className={isTruncated ? 'truncate' : ''} onClick={() => setIsTruncated(!isTruncated)}>
      {children}
    </Table.Cell>
  );
};

export default TruncatedTableCell;
