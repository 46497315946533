import { connect } from 'react-redux';

import {
  getMerchant,
  getMerchantHistory,
  getMerchantImageHistory,
  getMerchantCategoryHistory,
  getMerchantNetworkMerchants,
  updateMerchant,
  getMerchantKeywords,
  upsertMerchantKeyword,
  deleteMerchantKeyword,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
} from '../../actions/merchantAdmin';

import { getLastUpdated } from '../../selectors/merchantAdmin';
import { getUserCanEdit } from 'selectors/user';

import Merchant from './Merchant';

const mapStateToProps = state => ({
  lastUpdated: getLastUpdated(state),
  canEdit: getUserCanEdit(state),
});

const mapActionsToProps = {
  getMerchant,
  getMerchantHistory,
  getMerchantImageHistory,
  getMerchantCategoryHistory,
  getMerchantNetworkMerchants,
  updateMerchant,
  getMerchantKeywords,
  upsertMerchantKeyword,
  deleteMerchantKeyword,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
};

export default connect(mapStateToProps, mapActionsToProps)(Merchant);
