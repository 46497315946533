import React from 'react';
import { Table } from 'semantic-ui-react';
import CommissionSearchRow from './CommissionSearchRow';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

export interface TableHeader {
  key: string;
  text: string;
  value: string;
}

const tableHeaders = [
  {
    key: 'CommissionID',
    text: 'Commission ID',
    value: 'Commission ID',
  },
  {
    key: 'ApplicationID',
    text: 'Application ID',
    value: 'Application ID',
  },
  {
    key: 'MerchantID',
    text: 'Merchant ID',
    value: 'Merchant ID',
  },
  {
    key: 'DeviceID',
    text: 'Device ID',
    value: 'Device ID',
  },
  {
    key: 'EventDate',
    text: 'Event Date',
    value: 'Event Date',
  },
  {
    key: 'SaleAmount',
    text: 'Sale Amount',
    value: 'Sale Amount',
  },
  {
    key: 'Amounts',
    text: 'Commission Amounts',
    value: 'Amounts',
  },
  {
    key: 'LockingDate',
    text: 'Locking Date',
    value: 'Locking Date',
  },
  {
    key: 'CreatedDate',
    text: 'Created Date',
    value: 'Created Date',
  },
  {
    key: 'ModifiedDate',
    text: 'Modified Date',
    value: 'Modified Date',
  },
  {
    key: 'MerchantOrderID',
    text: 'Order ID',
    value: 'Order ID',
  },
  {
    key: 'TrackingCode',
    text: 'Tracking Code',
    value: 'Tracking Code',
  },
  {
    key: 'MerchantSKU',
    text: 'Merchant SKU',
    value: 'Merchant SKU',
  },
];

interface CommissionSearchTableProps {
  commissions: any[];
  isCommissionsLoading: boolean;
}

const CommissionSearchTable = (props: CommissionSearchTableProps) => {
  const { commissions, isCommissionsLoading } = props;

  const renderBodyRows = () => {
    return commissions.map((commission: any) => (
      <CommissionSearchRow key={commission.CommissionID} commission={commission} headers={tableHeaders} />
    ));
  };

  if (isCommissionsLoading) return <LoadingOverlay isLoading />;
  return (
    <Table celled>
      <Table.Header id="tableHeader">
        <Table.Row>
          {tableHeaders.map(({ text }: TableHeader) => {
            return (
              <Table.HeaderCell>
                <span>{text}</span>
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderBodyRows()}</Table.Body>
    </Table>
  );
};

export default CommissionSearchTable;
