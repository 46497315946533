import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { NetworksData } from 'constants/networks';
import { getNetworkDropdownOptions, getNetworkName, toPrettyAmount, getEmptyDropdown } from './helpers';
import { toPrettyTime } from '../../helpers';
import { Button, Table, Dimmer, Loader, Segment } from 'semantic-ui-react';
import PaginateControl from './PaginateControl';

const PAGE_SIZE = 25;

const ItemsColumn = ({ Failed, Started, Success }) => {
  return (
    <>
      {Success > 0 && <span>Success: {Success}</span>}
      <br />
      {Failed > 0 && <span>Failed: {Failed}</span>}
      <br />
      {Started > 0 && <span>Started: {Started}</span>}
    </>
  );
};

const GetReconcileJobs = ({ getReconcileJobs }) => {
  const history = useHistory();
  const [queryData, setQueryData] = useState({
    network: '',
    page: 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rows, setRows] = useState([]);

  const networkDropdownOptions = [getEmptyDropdown(), ...getNetworkDropdownOptions()];

  useEffect(() => {
    const sanitizedQuery = { ...queryData, page_size: PAGE_SIZE };
    // swap network name for ID using NetworkIdDictionary
    const networkConfig = NetworksData[queryData.network];
    if (networkConfig) {
      sanitizedQuery.network_id = networkConfig.id;
    }
    const fetchJobs = async () => {
      setErrorMessage('');
      setIsLoading(true);
      try {
        const Jobs = await getReconcileJobs(sanitizedQuery);
        setRows(Jobs);
      } catch (e) {
        console.log(e);
        setErrorMessage(e.error?.ErrorMessage || e.message || 'An unknown error has occurred while fetching jobs.');
      }
      setIsLoading(false);
    };
    fetchJobs();
  }, [queryData]);

  const handleChange = (e, { name, value }) => {
    setQueryData({ ...queryData, [name]: value });
  };

  const goToJob = id => {
    history.push(`/reconciliation/${id}`);
  };

  if (errorMessage) {
    return (
      <div>
        <p>{errorMessage}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  return (
    <>
      <Form className="get-reconcile-jobs" autoComplete="off">
        <Form.Group style={{ justifyContent: 'space-between' }}>
          <Form.Dropdown
            label="Network"
            name="network"
            options={networkDropdownOptions}
            onChange={handleChange}
            value={queryData.network}
            search
            selection
            placeholder="Select Network"
          />
          <PaginateControl
            page={queryData.page}
            prevDisabled={queryData.page === 1 || isLoading}
            nextDisabled={isLoading}
            onPrev={() => setQueryData(data => ({ ...data, page: data.page - 1 }))}
            onNext={() => setQueryData(data => ({ ...data, page: data.page + 1 }))}
          />
        </Form.Group>
      </Form>
      <Table>
        <thead style={{ fontWeight: 'bold', fontSize: 18, backgroundColor: '#e8e5e3' }}>
          <tr>
            <th>Network</th>
            <th>Date</th>
            <th>Amounts</th>
            <th>Status</th>
            <th>ItemCounts</th>
            <th>CreatedDate</th>
            <th>ModifiedDate</th>
            <th>RetryCount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map(
            ({
              Id,
              NetworkId,
              Currency,
              Date,
              Status,
              NumItems,
              RetryCount,
              CreatedDate,
              ModifiedDate,
              Amount,
              BankAmount,
              Started,
              Success,
              Failed,
            }) => {
              const networkName = getNetworkName(NetworkId);
              const money = toPrettyAmount(Amount, Currency, BankAmount);
              return (
                <tr key={Id}>
                  <td title={networkName}>{networkName}</td>
                  <td>{toPrettyTime(Date)}</td>
                  <td>{money}</td>
                  <td>{Status}</td>
                  <td>{ItemsColumn({ Started, Success, Failed })}</td>
                  <td>{toPrettyTime(CreatedDate, true)}</td>
                  <td>{toPrettyTime(ModifiedDate, true)}</td>
                  <td>{RetryCount}</td>
                  <td>
                    <Button
                      onClick={() => {
                        goToJob(Id);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      View Job Details
                    </Button>
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
      </Table>
    </>
  );
};

export default GetReconcileJobs;
