import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon, Label, Modal } from 'semantic-ui-react';

import KeywordDetail from '../KeywordDetail/KeywordDetail';
import { connect } from 'react-redux';
import { getUserCanEdit } from 'selectors/user';

import './KeywordTag.css';

class KeywordTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  removeKeyword = async () => {
    const { handleRemoveKeyword, keyword } = this.props;
    this.setState({ hasError: false });
    try {
      await handleRemoveKeyword(keyword);
    } catch (error) {
      this.setState({ hasError: true });
    }
  };

  // only for merchant keywords that are out of sync
  syncKeyword = () => {
    const { syncKeyword, keyword } = this.props;
    syncKeyword(keyword);
  };

  render() {
    const { hasError } = this.state;
    const { handleUpdateKeyword, keyword, isLastDomainKeyword, isLastSyncedDomainKeyword, viewOnly, canEdit } =
      this.props;
    const isViewOnly = viewOnly ?? !canEdit;
    return (
      <div className="keyword-tag">
        <Label color={hasError ? 'red' : undefined} data-testid="keyword-tag">
          <Modal trigger={<span className="name">{keyword.KeywordName}</span>}>
            <Modal.Header>
              <Icon name="tag" />
              Keyword (ID: {keyword.KeywordID})
            </Modal.Header>
            <Modal.Content>
              <KeywordDetail
                keyword={keyword}
                updateKeyword={handleUpdateKeyword}
                isMerchantKeyword={typeof keyword.Synced !== 'undefined'}
                viewOnly={isViewOnly}
              />
            </Modal.Content>
          </Modal>
          <Label.Detail>{keyword.Kind}</Label.Detail>
          {!isViewOnly && (
            <Label.Detail>
              {typeof keyword.Synced === 'undefined' ? (
                // remove network merchant keyword
                isLastDomainKeyword ? null : (
                  <Icon name="remove" onClick={this.removeKeyword} title="remove from network merchant" />
                )
              ) : // remove or sync merchant keyword
              keyword.Synced ? (
                // remove merchant keyword
                isLastSyncedDomainKeyword ? null : (
                  <Icon name="remove" onClick={this.removeKeyword} title="remove from all network merchants" />
                )
              ) : (
                <Icon name="sync alternate" onClick={this.syncKeyword} title="sync between all network merchants" />
              )}
            </Label.Detail>
          )}
        </Label>
      </div>
    );
  }
}

KeywordTag.propTypes = {
  keyword: PropTypes.shape({
    Sync: PropTypes.bool,
    ID: PropTypes.number.isRequired,
    KeywordName: PropTypes.string.isRequired,
    Kind: PropTypes.string.isRequired,
    SearchCode: PropTypes.string.isRequired,
  }),
  // merchant keyword and out of sync
  syncKeyword: PropTypes.func,
  handleUpdateKeyword: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  canEdit: PropTypes.bool,
  isLastDomainKeyword: PropTypes.bool,
  isLastSyncedDomainKeyword: PropTypes.bool,
  handleRemoveKeyword: PropTypes.func,
};

const mapStateToProps = state => ({
  canEdit: getUserCanEdit(state),
});

export default connect(mapStateToProps, null)(KeywordTag);
