import React, { Component } from 'react';
import { Segment, Icon, Loader, Input as InputUI, Modal, Label, Checkbox, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Input from '../Input/Input';
import KeywordList from '../Keyword/KeywordList/KeywordList';
import HistoryLog from '../HistoryLogV2/HistoryLog';
import CouponModal from '../Coupon/CouponModal';
import CommissionsModal from '../Commission/CommissionsModal';
import LockLabel from '../LockLabel/LockLabel';
import DisabledLabel from '../DisabledLabel/DisabledLabel';
import Lock from '../Lock/Lock';
import MerchantSearchContainer from '../Merchant/MerchantSearch/MerchantSearchContainer';
import NotFound from '../NotFound/NotFound';
import { isNetworkMerchant } from './helpers';

import TextArea from 'components/TextArea/TextArea';
import {
  CountryCodes,
  europeCountryCodes,
  allCountryCodes,
  seaCountryCodes,
  unitedStateCountryCodes,
} from '../../constants/countryCodes';
import { getDifferingProperties, findErrorMessage } from 'helpers';
import './NetworkMerchant.css';

class NetworkMerchant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      networkMerchant: this.props.networkMerchant,
      keywordsIsLoading: false,
      keywords: [],
      commissionHistory: null,
      commissionHistoryIsLoading: false,
      history: null,
      historyIsLoading: false,
      commissions: [],
      commissionsIsLoading: false,
      currentMerchant: null,
      notFound: false,
      testUrl: '',
      countryInput: '',
      countryChanged: false,
      selectedCountries: [],
      countryCheckboxes: {
        'US + US Territories': false,
        Europe: false,
        'South East Asia': false,
        'All Countries': false,
      },
    };
  }

  getNetworkMerchant = async id => {
    try {
      const { getNetworkMerchant, includeKeywords, getMerchant, isRoot } = this.props;
      this.setState({ isLoading: true });
      const networkMerchant = await getNetworkMerchant(id);
      if (!networkMerchant || !isNetworkMerchant(networkMerchant)) {
        return this.setState({ networkMerchant: null, isLoading: false });
      }
      let currentMerchant = null;
      if (networkMerchant.MerchantID && isRoot) {
        currentMerchant = await getMerchant(networkMerchant.MerchantID);
      }
      this.setState({
        networkMerchant,
        currentMerchant,
        isLoading: false,
        countryInput: networkMerchant.Countries,
      });
      if (includeKeywords) {
        this.getNetworkMerchantKeywords();
      }
      this.getNetworkMerchantHistory();
      this.getNetworkMerchantCommissionHistory();
      this.getNetworkMerchantCommissions();
    } catch (e) {
      this.setState({ isLoading: false });
      const status = e?.response?.statusCode || e?.response?.status || e?.status;
      if (status === 404) {
        this.setState({ notFound: true });
      } else {
        return Promise.reject(e);
      }
    }
  };

  updateNetworkMerchant = async update => {
    const { updateNetworkMerchant } = this.props;
    const { networkMerchant } = this.state;
    const userEmail = localStorage.getItem('userEmail');

    try {
      const updatedNetworkMerchant = {
        ...networkMerchant,
        ...update,
        ModifiedAuthor: userEmail ? userEmail : 'merchant admin nm update',
      };
      await updateNetworkMerchant(updatedNetworkMerchant);
      this.setState({ networkMerchant: updatedNetworkMerchant });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getNetworkMerchantKeywords = async () => {
    const { networkMerchant } = this.state;
    const { getNetworkMerchantKeywords } = this.props;

    if (!networkMerchant) return;

    this.setState({ keywordsIsLoading: true });
    try {
      const response = await getNetworkMerchantKeywords(networkMerchant.ID);
      if (!Array.isArray(response)) {
        throw new Error(findErrorMessage(response));
      }
      this.setState({
        keywords: response,
        keywordsIsLoading: false,
      });
    } catch (error) {
      this.setState({ keywordsIsLoading: false });
      return Promise.reject(error);
    }
  };

  getNetworkMerchantHistory = async () => {
    const { getNetworkMerchantHistory, getNetworkMerchantCommissionRateHistory, getNetworkMerchantCountryHistory } =
      this.props;
    const { networkMerchant } = this.state;
    this.setState({ historyIsLoading: true });
    try {
      const [history, commissionRateHistory, countryHistory] = await Promise.all([
        getNetworkMerchantHistory(networkMerchant.ID),
        getNetworkMerchantCommissionRateHistory(networkMerchant.ID),
        getNetworkMerchantCountryHistory(networkMerchant.ID),
      ]);
      const sortByTimestamp = (a, b) => {
        const dateA = new Date(a.Timestamp);
        const dateB = new Date(b.Timestamp);
        return dateB - dateA;
      };
      this.setState({
        history: [...history, ...commissionRateHistory, ...countryHistory].sort(sortByTimestamp),
        historyIsLoading: false,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getNetworkMerchantCommissionHistory = async () => {
    const { networkMerchant } = this.state;
    const { getNetworkMerchantCommissionHistory } = this.props;
    this.setState({ commissionHistoryIsLoading: true });
    try {
      const commissionHistory = await getNetworkMerchantCommissionHistory(networkMerchant.ID);
      this.setState({
        commissionHistory,
        commissionHistoryIsLoading: false,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getNetworkMerchantCommissions = async () => {
    const { networkMerchant } = this.state;
    const { getNetworkMerchantCommissions } = this.props;
    try {
      this.setState({ commissionsIsLoading: true });
      const commissions = await getNetworkMerchantCommissions(networkMerchant.ID, { sortBy: 'disabled' });
      this.setState({
        commissions,
        commissionsIsLoading: false,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateNetworkMerchantCommission = async updatedCommission => {
    const { updateNetworkMerchantCommission } = this.props;
    const { commissions } = this.state;
    const newCommissions = [...commissions].map(commission => {
      if (commission.ID === updatedCommission.ID) {
        // Lock any fields whose value has changed except Locks
        const updatedFields = getDifferingProperties(commission, updatedCommission).filter(field => field !== 'Locks');
        updatedCommission.Locks = [...new Set([...updatedCommission.Locks, ...updatedFields])];

        return updatedCommission;
      }
      return commission;
    });
    try {
      await updateNetworkMerchantCommission(updatedCommission);
      this.setState({ commissions: newCommissions });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateNetworkMerchantCountries = async () => {
    const { updateNetworkMerchant } = this.props;
    const { networkMerchant } = this.state;
    const selectedCountries = this.state.selectedCountries;
    const userEmail = localStorage.getItem('userEmail');

    const selectedCountriesSet = new Set(selectedCountries);

    if (selectedCountriesSet.has('All Countries')) {
      const updatedNetworkMerchant = {
        ...networkMerchant,
        Countries: [...new Set(allCountryCodes)],
        ModifiedAuthor: userEmail ?? 'merchant admin nm update',
      };
      await updateNetworkMerchant(updatedNetworkMerchant);
      this.setState({
        countryChanged: false,
        selectedCountries: [...selectedCountriesSet],
        countryCheckboxes: {
          'US + US Territories': false,
          Europe: false,
          'South East Asia': false,
          'All Countries': false,
        },
        networkMerchant: {
          ...networkMerchant,
          Countries: [...new Set(allCountryCodes)],
        },
      });
      return;
    }

    let updatedCountries = [];
    selectedCountries.forEach(countryString => {
      switch (countryString) {
        case 'South East Asia': {
          updatedCountries = [...updatedCountries, ...seaCountryCodes];
          break;
        }
        case 'US + US Territories': {
          updatedCountries = [...updatedCountries, ...unitedStateCountryCodes];
          break;
        }
        case 'Europe': {
          updatedCountries = [...updatedCountries, ...europeCountryCodes];
          break;
        }
        default: {
          updatedCountries.push(countryString);
        }
      }
    });

    // Removes Any Duplicates that the Grouped values may have added.
    const filteredSelectedCountries = [...new Set(updatedCountries)];

    try {
      const updatedNetworkMerchant = {
        ...networkMerchant,
        Countries: filteredSelectedCountries,
        ModifiedAuthor: userEmail ? userEmail : 'merchant admin nm update',
      };

      await updateNetworkMerchant(updatedNetworkMerchant);
      this.setState({
        countryChanged: false,
        selectedCountries: filteredSelectedCountries,
        countryCheckboxes: {
          'US + US Territories': false,
          Europe: false,
          'South East Asia': false,
          'All Countries': false,
        },
        networkMerchant: {
          ...networkMerchant,
          Countries: filteredSelectedCountries,
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  upsertNetworkMerchantKeyword = async keyword => {
    const { networkMerchant } = this.state;
    const { upsertNetworkMerchantKeyword } = this.props;
    try {
      await upsertNetworkMerchantKeyword(networkMerchant.ID, keyword);
      await this.getNetworkMerchantKeywords();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  removeNetworkMerchantKeyword = async keyword => {
    const { networkMerchant } = this.state;
    const { disableNetworkMerchantKeyword } = this.props;
    try {
      await disableNetworkMerchantKeyword(networkMerchant.ID, keyword);
      await this.getNetworkMerchantKeywords();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateNetworkMerchantMerchant = async MerchantID => {
    const { getMerchant } = this.props;
    this.updateNetworkMerchant({ MerchantID });
    const currentMerchant = MerchantID !== 0 ? await getMerchant(MerchantID) : null;
    this.setState({ currentMerchant });
  };

  goToNetworkMerchant = () => {
    const { networkMerchant } = this.state;
    const { history } = this.props;
    history.push(`/merchant-admin/network_merchant/${networkMerchant.ID}`);
  };

  goToMerchant = () => {
    const { networkMerchant } = this.state;
    const { history } = this.props;
    history.push(`/merchant-admin/merchant/${networkMerchant.MerchantID}`);
  };

  getTestUrl = () => {
    const { networkMerchant } = this.state;
    const { networks } = this.props;
    if (!networks || isEmpty(networkMerchant)) {
      return '';
    }
    const network = networks[networkMerchant.NetworkID];
    const templateUrl = network.TemplateURL;

    if (Number(networkMerchant.NetworkID) === 10) {
      // Use networkMerchant.BannerID to set the AffiliateCode for AvantLink
      return (
        templateUrl
          .replace('[[AffiliateCode]]', networkMerchant.BannerID)
          .replace('[[MerchantCode]]', networkMerchant.RedirectCode || networkMerchant.XID)
          .replace('[[BannerCode]]', networkMerchant.BannerID)
          .replace('[[DeepLinkURL]]', networkMerchant.URL)
          // replace with sender token so that if a transaction does happen, it does not cause errors in the system
          .replace('[[SubAffiliateCode]]', localStorage.getItem('deviceId') || '1')
          // this is currently not used on our end
          .replace('[[RecipientCode]]', '')
      );
    }

    return (
      templateUrl
        .replace('[[AffiliateCode]]', network.AffiliateCode)
        .replace('[[MerchantCode]]', networkMerchant.RedirectCode || networkMerchant.XID)
        .replace('[[BannerCode]]', networkMerchant.BannerID)
        .replace('[[DeepLinkURL]]', networkMerchant.URL)
        // replace with sender token so that if a transaction does happen, it does not cause errors in the system
        .replace('[[SubAffiliateCode]]', localStorage.getItem('deviceId') || '1')
        // this is currently not used on our end
        .replace('[[RecipientCode]]', '')
    );
  };

  async componentDidMount() {
    const { includeKeywords, match } = this.props;
    const { networkMerchant } = this.state;
    try {
      if (!networkMerchant) {
        await this.getNetworkMerchant(match.params.networkMerchantId);
      } else {
        if (includeKeywords) {
          await this.getNetworkMerchantKeywords();
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps) {
    const { includeKeywords, match } = this.props;
    const { networkMerchant, isLoading } = this.state;

    if (
      !isLoading &&
      ((match && match.params.networkMerchantId !== prevProps.match.params.networkMerchantId) || !networkMerchant)
    ) {
      this.setState({
        isLoading: true,
        notFound: false,
      });
      try {
        await this.getNetworkMerchant(match.params.networkMerchantId);
        if (includeKeywords) {
          await this.getNetworkMerchantKeywords();
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }

  getCountryInput = (e, data) => {
    if (data.value.length === this.state.networkMerchant.Countries.length) {
      data.value.sort();
      this.state.networkMerchant.Countries.sort();
      this.state.networkMerchant.Countries.every((c, i) => c === data.value[i])
        ? this.setState({ countryChanged: false })
        : this.setState({ countryChanged: true });
    } else {
      this.setState({ countryChanged: true });
    }
    this.setState({ countryInput: data.value });
  };

  onCountryChange = (event, data) => {
    this.setState({
      selectedCountries: data.value,
      countryChanged: true,
    });
  };

  onCountryToggleOn = countryString => {
    let newSelectedCountries = [];
    if (this.state.countryChanged === false) {
      newSelectedCountries = [...this.state.networkMerchant.Countries];
    }
    switch (countryString) {
      case 'US + US Territories': {
        this.setState({
          selectedCountries: [...newSelectedCountries, ...this.state.selectedCountries, 'US + US Territories'],
          countryChanged: true,
          networkMerchant: {
            ...this.state.networkMerchant,
            Countries: newSelectedCountries,
          },
        });
        break;
      }
      case 'Europe': {
        this.setState({
          selectedCountries: [...newSelectedCountries, ...this.state.selectedCountries, 'Europe'],
          countryChanged: true,
          networkMerchant: {
            ...this.state.networkMerchant,
            Countries: newSelectedCountries,
          },
        });
        break;
      }
      case 'South East Asia': {
        this.setState({
          selectedCountries: [...newSelectedCountries, ...this.state.selectedCountries, 'South East Asia'],
          countryChanged: true,
          networkMerchant: {
            ...this.state.networkMerchant,
            Countries: newSelectedCountries,
          },
        });
        break;
      }
      case 'All Countries': {
        this.setState({
          selectedCountries: ['All Countries'],
          countryChanged: true,
          networkMerchant: {
            ...this.state.networkMerchant,
            Countries: ['All Countries'],
          },
        });
        break;
      }
      default:
        console.warn('Unknown countryString in onCountryToggleOn');
    }
  };

  onCountryToggleOff = countryString => {
    let newSelectedCountries = [];

    // On First load or after save, it should not have the grouped country tags. it should already be converted to individual countries
    if (!this.state.countryChanged) return;

    newSelectedCountries = [...this.state.selectedCountries];
    const filteredSelectedCountries = newSelectedCountries.filter(country => country !== countryString);

    this.setState({
      selectedCountries: filteredSelectedCountries,
      countryChanged: true,
    });
  };

  render() {
    const { includeKeywords, isRoot, isMerchantView, networks, isDQContractor, canEdit } = this.props;
    const {
      networkMerchant,
      keywords,
      keywordsIsLoading,
      history,
      historyIsLoading,
      commissionHistory,
      commissionHistoryIsLoading,
      commissions,
      isLoading,
      currentMerchant,
      notFound,
    } = this.state;

    const testUrl = this.getTestUrl();

    if (notFound) {
      return <NotFound />;
    }

    if (isLoading || !networkMerchant) {
      return <Loader active size="massive" inline="centered" />;
    }

    return (
      <Segment.Group className="network-merchant">
        <Segment>
          {!isDQContractor && canEdit ? (
            <DisabledLabel
              isDisabled={networkMerchant.Disabled}
              handleResourceChange={this.updateNetworkMerchant}
              locks={networkMerchant.Locks}
              uneditable={isDQContractor}
              titleLabel="Network Merchant"
            >
              <Lock
                locks={networkMerchant.Locks}
                handleResourceChange={this.updateNetworkMerchant}
                name="Disabled"
                disabled={isDQContractor}
              />
            </DisabledLabel>
          ) : (
            <Label color={networkMerchant.Disabled ? 'red' : 'green'} attached="top right">
              <span>{networkMerchant.Disabled ? 'Disabled' : 'Enabled'}</span>
            </Label>
          )}
          <h3 className="header">
            <span onClick={this.goToNetworkMerchant} style={{ cursor: 'pointer' }}>
              <Icon name="cubes" />
              Network Merchant
            </span>{' '}
            (ID: {networkMerchant.ID})
          </h3>
          <div className="network-merchant-details">
            <Input
              label={
                <LockLabel
                  label="Name"
                  locks={networkMerchant.Locks}
                  handleResourceChange={this.updateNetworkMerchant}
                  disabled={isDQContractor || !canEdit}
                />
              }
              value={networkMerchant.Name}
              handleResourceChange={this.updateNetworkMerchant}
              disabled={isDQContractor || !canEdit}
            />
            <InputUI
              className="input-field"
              label="CreatedDate"
              value={new Date(networkMerchant.CreatedDate).toLocaleDateString()}
              disabled
              fluid
            />
            <InputUI
              className="input-field"
              label="Network"
              value={`${networkMerchant.NetworkID} (${
                networks?.[networkMerchant.NetworkID]?.Name || 'Unknown Network'
              })`}
              disabled
              fluid
            />
            <InputUI className="input-field" label="XID" value={networkMerchant.XID} disabled fluid />
            {isRoot && (
              <Modal
                trigger={
                  <InputUI
                    className="input-field"
                    label={
                      <Label
                        content={
                          <div className="merchant-label">
                            MerchantID
                            <Icon
                              className="cube-icon"
                              name="cube"
                              onClick={networkMerchant.MerchantID ? this.goToMerchant : null}
                            />
                          </div>
                        }
                      />
                    }
                    value={`${networkMerchant.MerchantID} (${currentMerchant ? currentMerchant.Name : 'NONE'})`}
                    fluid
                    disabled={!canEdit}
                  />
                }
                centered={false}
              >
                <Modal.Header>Search Merchants</Modal.Header>
                <Modal.Content>
                  <MerchantSearchContainer
                    merchantId={networkMerchant.MerchantID}
                    handleResultSelect={this.updateNetworkMerchantMerchant}
                    currentMerchant={currentMerchant}
                  />
                </Modal.Content>
              </Modal>
            )}
            {!isDQContractor && (
              <Input
                label={
                  <LockLabel
                    label="BannerID"
                    locks={networkMerchant.Locks}
                    handleResourceChange={this.updateNetworkMerchant}
                    disabled={isDQContractor || !canEdit}
                  />
                }
                value={networkMerchant.BannerID}
                handleResourceChange={this.updateNetworkMerchant}
                disabled={!canEdit}
              />
            )}
            <Input
              label={
                <LockLabel
                  label="URL"
                  locks={networkMerchant.Locks}
                  handleResourceChange={this.updateNetworkMerchant}
                  disabled={isDQContractor || !canEdit}
                />
              }
              value={networkMerchant.URL}
              handleResourceChange={this.updateNetworkMerchant}
              disabled={isDQContractor || !canEdit}
            />
            <Input
              label={
                <LockLabel
                  label="SearchURL"
                  locks={networkMerchant.Locks}
                  handleResourceChange={this.updateNetworkMerchant}
                  disabled={isDQContractor || !canEdit}
                />
              }
              value={networkMerchant.SearchURL}
              handleResourceChange={this.updateNetworkMerchant}
              disabled={isDQContractor || !canEdit}
            />
            <InputUI className="input-field" label="RedirectCode" value={networkMerchant.RedirectCode} disabled fluid />
            <InputUI className="input-field" label="MaxRate" value={networkMerchant.MaxRate} disabled fluid />
            <InputUI
              className="input-field"
              label="DisabledReason"
              value={networkMerchant.DisabledReason}
              disabled
              fluid
            />
            <div className="country-search-box-container">
              <LockLabel
                className="label country-label"
                label="Countries"
                locks={networkMerchant.Locks}
                handleResourceChange={this.updateNetworkMerchant}
                disabled={!canEdit}
              />
              <div className="search-bar-container">
                {canEdit && (
                  <div className="checkbox-container">
                    <Checkbox
                      label="All Countries"
                      checked={this.state.countryCheckboxes['All Countries']}
                      onChange={(event, data) => {
                        if (data.checked) {
                          this.onCountryToggleOn('All Countries');
                          this.setState({
                            countryCheckboxes: {
                              ...this.state.countryCheckboxes,
                              'All Countries': true,
                            },
                          });
                        } else {
                          this.onCountryToggleOff('All Countries');
                          this.setState({
                            countryCheckboxes: {
                              ...this.state.countryCheckboxes,
                              'All Countries': false,
                            },
                          });
                        }
                      }}
                    />
                    <Checkbox
                      label="US + US Territories"
                      checked={this.state.countryCheckboxes['US + US Territories']}
                      onChange={(event, data) => {
                        if (data.checked) {
                          this.onCountryToggleOn('US + US Territories');
                          this.setState({
                            countryCheckboxes: {
                              ...this.state.countryCheckboxes,
                              'US + US Territories': true,
                            },
                          });
                        } else {
                          this.onCountryToggleOff('US + US Territories');
                          this.setState({
                            countryCheckboxes: {
                              ...this.state.countryCheckboxes,
                              'US + US Territories': false,
                            },
                          });
                        }
                      }}
                    />
                    <Checkbox
                      label="Europe"
                      checked={this.state.countryCheckboxes['Europe']}
                      onChange={(event, data) => {
                        if (data.checked) {
                          this.onCountryToggleOn('Europe');
                          this.setState({
                            countryCheckboxes: {
                              ...this.state.countryCheckboxes,
                              Europe: true,
                            },
                          });
                        } else {
                          this.onCountryToggleOff('Europe');
                          this.setState({
                            countryCheckboxes: {
                              ...this.state.countryCheckboxes,
                              Europe: false,
                            },
                          });
                        }
                      }}
                    />
                    <Checkbox
                      label="South East Asia"
                      checked={this.state.countryCheckboxes['South East Asia']}
                      onChange={(event, data) => {
                        if (data.checked) {
                          this.onCountryToggleOn('South East Asia');
                          this.setState({
                            countryCheckboxes: {
                              ...this.state.countryCheckboxes,
                              'South East Asia': true,
                            },
                          });
                        } else {
                          this.onCountryToggleOff('South East Asia');
                          this.setState({
                            countryCheckboxes: {
                              ...this.state.countryCheckboxes,
                              'South East Asia': false,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                )}

                <Form.Dropdown
                  className="dropdown"
                  fluid
                  multiple
                  selection
                  clearable
                  search
                  name="countries"
                  options={CountryCodes}
                  value={
                    this.state.selectedCountries.length === 0 && !this.state.countryChanged
                      ? this.state.networkMerchant.Countries
                      : this.state.selectedCountries
                  }
                  onChange={this.onCountryChange}
                  disabled={!canEdit}
                />
              </div>
              <Form.Button
                className="button countries-submit"
                type="submit"
                onClick={this.updateNetworkMerchantCountries}
                disabled={!canEdit || !this.state.countryChanged}
              >
                Save
              </Form.Button>
            </div>
            <TextArea
              label={
                <LockLabel
                  label="Note"
                  locks={networkMerchant.Locks}
                  handleResourceChange={this.updateNetworkMerchant}
                  disabled={isDQContractor || !canEdit}
                  className={isDQContractor || !canEdit ? 'disabled' : ''}
                />
              }
              value={networkMerchant.Note.split(';')
                .map(item => item.trim())
                .join('\n')}
              handleResourceChange={this.updateNetworkMerchant}
              disabled={isDQContractor || !canEdit}
            />
            <InputUI
              className="input-field"
              label={
                <Label className="test-link-label">
                  Test Link
                  <a href={testUrl} target="_blank" rel="noopener noreferrer">
                    <Icon name="external alternate" />
                  </a>
                </Label>
              }
              value={testUrl}
              fluid
              disabled={!canEdit}
            />
          </div>
          <div className="checkboxes-column">
            <div className="toggle-checkbox">
              <Checkbox
                toggle
                label={
                  <label>
                    <span className="checkbox-lock-container">
                      <Lock
                        locks={networkMerchant.Locks}
                        name="IsITPCompliant"
                        handleResourceChange={this.updateNetworkMerchant}
                        disabled={!canEdit}
                      />
                      ITP Compliant
                    </span>
                  </label>
                }
                checked={networkMerchant.IsITPCompliant}
                onChange={() => {
                  this.updateNetworkMerchant({
                    IsITPCompliant: !networkMerchant.IsITPCompliant,
                    Locks: [...networkMerchant.Locks, 'IsITPCompliant'],
                  });
                }}
                disabled={!canEdit}
              />
            </div>
            {!isDQContractor && (
              <>
                <div className="toggle-checkbox">
                  <Checkbox
                    toggle
                    label={
                      <label>
                        <span className="checkbox-lock-container">
                          <Lock
                            locks={networkMerchant.Locks}
                            name="DeeplinkDisabled"
                            handleResourceChange={this.updateNetworkMerchant}
                            disabled={!canEdit}
                          />
                          Deeplink Disabled
                        </span>
                      </label>
                    }
                    checked={networkMerchant.DeeplinkDisabled}
                    onChange={() => {
                      this.updateNetworkMerchant({
                        DeeplinkDisabled: !networkMerchant.DeeplinkDisabled,
                        Locks: [...networkMerchant.Locks, 'DeeplinkDisabled'],
                      });
                    }}
                    disabled={!canEdit}
                  />
                </div>
                <div className="toggle-checkbox">
                  <Checkbox
                    toggle
                    label={
                      <label>
                        <span className="checkbox-lock-container">
                          <Lock
                            locks={networkMerchant.Locks}
                            name="CouponDisabled"
                            handleResourceChange={this.updateNetworkMerchant}
                            disabled={!canEdit}
                          />
                          Coupon Disabled
                        </span>
                      </label>
                    }
                    checked={networkMerchant.CouponDisabled}
                    onChange={() => {
                      this.updateNetworkMerchant({
                        CouponDisabled: !networkMerchant.CouponDisabled,
                        Locks: [...networkMerchant.Locks, 'CouponDisabled'],
                      });
                    }}
                    disabled={!canEdit}
                  />
                </div>
                <div className="toggle-checkbox">
                  <Checkbox
                    toggle
                    label={
                      <label>
                        <span className="checkbox-lock-container">
                          <Lock
                            locks={networkMerchant.Locks}
                            name="ExistingCustomerRateDisabled"
                            handleResourceChange={this.updateNetworkMerchant}
                            disabled={!canEdit}
                          />
                          Pays New Customers Only
                        </span>
                      </label>
                    }
                    checked={networkMerchant.ExistingCustomerRateDisabled}
                    onChange={() => {
                      this.updateNetworkMerchant({
                        ExistingCustomerRateDisabled: !networkMerchant.ExistingCustomerRateDisabled,
                        Locks: [...networkMerchant.Locks, 'ExistingCustomerRateDisabled'],
                      });
                    }}
                    disabled={!canEdit}
                  />
                </div>
              </>
            )}
          </div>
        </Segment>
        {includeKeywords && !isDQContractor && (
          <KeywordList
            isLoading={keywordsIsLoading}
            keywords={keywords}
            handleUpsertKeyword={this.upsertNetworkMerchantKeyword}
            handleRemoveKeyword={this.removeNetworkMerchantKeyword}
          />
        )}
        {!isDQContractor && (
          <CouponModal
            getCoupons={this.props.getCoupons}
            updateCoupon={this.props.updateCoupon}
            createCoupon={this.props.createCoupon}
            sortBy={'disabled, code'}
            sortOrder={'nulls last'}
            networkMerchant={networkMerchant}
            setLastUpdated={this.props.setLastUpdated}
          />
        )}
        {commissions && !isMerchantView && (
          <CommissionsModal
            commissions={commissions}
            handleUpdateCommission={this.updateNetworkMerchantCommission}
            commissionHistory={commissionHistory}
            commissionHistoryIsLoading={commissionHistoryIsLoading}
            networkMerchant={networkMerchant}
          />
        )}

        {historyIsLoading && !isMerchantView && <Loader active size="massive" inline="centered" />}
        {!historyIsLoading && history && !isMerchantView && <HistoryLog history={history} />}
      </Segment.Group>
    );
  }
}

NetworkMerchant.propTypes = {
  networkMerchant: PropTypes.object,
  includeKeywords: PropTypes.bool,
  isMerchantView: PropTypes.bool,
  disableNetworkMerchantKeyword: PropTypes.func.isRequired,
  upsertNetworkMerchantKeyword: PropTypes.func.isRequired,
  getNetworkMerchantKeywords: PropTypes.func.isRequired,
  getNetworkMerchant: PropTypes.func.isRequired,
  getNetworkMerchantHistory: PropTypes.func.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isRoot: PropTypes.bool,
  isDQContractor: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default NetworkMerchant;
