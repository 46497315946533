import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { Segment, Icon, Input as InputUI } from 'semantic-ui-react';

import DisabledLabel from '../DisabledLabel/DisabledLabel';
import Input from '../Input/Input';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import FetchError from '../FetchError/FetchError';
import NotFound from '../NotFound/NotFound';

import api from '../../helpers/api';
import useApi from '../../hooks/useApi';

const NetworkMerchantHotel = ({ match, setLastUpdated, canEdit }) => {
  const { id } = match.params;

  const { isLoading, hasError, data: hotel, error, reFetch } = useApi(api.getNetworkMerchantHotel, {}, id);

  const patchHotel = async patch => {
    try {
      await api.patchNetworkMerchantHotel(id, patch);
    } catch (err) {
      return Promise.reject(error);
    }
    reFetch();
    setLastUpdated();
  };

  if (hasError) {
    if (error.status === 404) {
      return <NotFound />;
    }
    return <FetchError onRetry={reFetch} />;
  }

  return (
    <Segment.Group>
      <Segment>
        <LoadingOverlay isLoading={isLoading && isEmpty(hotel)} />
        <DisabledLabel
          isDisabled={hotel.Disabled}
          handleResourceChange={canEdit ? patchHotel : () => {}}
          titleLabel="Network Merchant Hotel"
        />
        <h3>
          <Icon name="home" />
          Network Merchant Hotel {hotel.ID && `(ID: ${hotel.ID})`}
        </h3>
        <Input
          label="Name"
          value={hotel.Name}
          handleResourceChange={canEdit ? patchHotel : () => {}}
          disabled={canEdit}
        />
        <Input
          label="SearchCode"
          value={hotel.SearchCode}
          handleResourceChange={canEdit ? patchHotel : () => {}}
          disabled={canEdit}
        />
        <Input
          label="DestinationURL"
          value={hotel.DestinationURL}
          handleResourceChange={canEdit ? patchHotel : () => {}}
          disabled={canEdit}
        />
        <Input
          label="Rank"
          value={hotel.Rank}
          handleResourceChange={canEdit ? patchHotel : () => {}}
          disabled={canEdit}
        />
        <InputUI label="XID" defaultValue={hotel.XID} disabled fluid />
        <InputUI label="PhoneNumber" defaultValue={hotel.PhoneNumber} disabled fluid />
        <InputUI label="Address" defaultValue={hotel.Address} disabled fluid />
        <InputUI label="City" defaultValue={hotel.City} disabled fluid />
        <InputUI label="Region" defaultValue={hotel.Region} disabled fluid />
        <InputUI label="PostalCode" defaultValue={hotel.PostalCode} disabled fluid />
      </Segment>
    </Segment.Group>
  );
};

NetworkMerchantHotel.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  setLastUpdated: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
};

export default NetworkMerchantHotel;
