import { connect } from 'react-redux';

import { getSearchDetailId } from '../../selectors/merchantAdmin';

import MerchantAdmin from './MerchantAdmin';

const mapStateToProps = state => ({
  searchDetailId: getSearchDetailId(state),
});

export default connect(mapStateToProps, null)(MerchantAdmin);
