import React from 'react';
import CommissionsTable from './CommissionsTable';
import CommissionHistoryLog from './CommissionHistoryLog';

import { Segment, Icon, Modal } from 'semantic-ui-react';
import './Commission.css';

const CommissionsModal = ({
  commissions,
  handleUpdateCommission,
  commissionHistory,
  commissionHistoryIsLoading,
  networkMerchant,
}) => {
  return (
    <Segment.Group className="commissions">
      <Modal
        className="centered"
        closeIcon={true}
        size="large"
        trigger={
          <Segment padded className="header">
            <h3 style={{ textAlign: 'center' }}>
              <Icon name="dollar" />
              Commissions ({commissions.length})
            </h3>
          </Segment>
        }
      >
        <Modal.Header>
          <Icon name="dollar" />
          Commissions (Active)
        </Modal.Header>
        <Modal.Content scrolling>
          <CommissionsTable commissions={commissions} handleUpdateCommission={handleUpdateCommission} />
          <CommissionHistoryLog
            commissionHistory={commissionHistory}
            commissions={commissions}
            isLoading={commissionHistoryIsLoading}
            nm={networkMerchant}
          />
        </Modal.Content>
      </Modal>
    </Segment.Group>
  );
};

export default CommissionsModal;
