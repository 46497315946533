import React, { useState, useEffect, useCallback } from 'react';
import { Button, Table, Dropdown, Dimmer, Loader, Segment, Icon } from 'semantic-ui-react';
import './AgingReport.css';

const AgingReport = ({ getAgingReport, getFilterOptions }) => {
  const [activeFilter, setActiveFilter] = useState({ key: 'General_0', text: 'General', value: 'General_0' });
  const [filterOptions, setFilterOptions] = useState([]);
  const [agingData, setAgingData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [drilldownView, setDrilldownView] = useState('aging');
  const FILTER_TYPES = ['General', 'Application', 'Network'];
  const [sortField, setSortField] = useState(null);
  const [order, setSortOrder] = useState('asc');

  const GetAgingReport = useCallback(
    async (month = '') => {
      setIsLoading(true);

      if (month === '') {
        setDrilldownView(false);
      }

      try {
        const parts = activeFilter.key.split('_');
        const res = await getAgingReport({
          type: parts[0].toLowerCase(),
          id: parts[1],
          month,
        });

        setAgingData(res?.body);
        setFileName(res?.headers['content-disposition']);
      } catch (err) {
        if (err.message.includes("storage: object doesn't exist")) {
          setError(new Error('Report has not yet been generated. Please come back in a few hours'));
        } else {
          setError(err);
        }
      }

      setIsLoading(false);
    },
    [activeFilter, getAgingReport],
  );

  const GetFilterOptions = useCallback(() => {
    const filterOptions = [];

    FILTER_TYPES.forEach(async f => {
      if (f === 'General') {
        filterOptions.push({ key: 'General_0', text: 'General', value: 'General_0' });
      } else {
        const options = await getFilterOptions(f.toLowerCase());

        options.sort((a, b) => a.ID - b.ID);

        filterOptions.push(
          ...options.map(o => {
            return {
              key: f + '_' + o.ID,
              text: f + ' ' + o.ID + ' - ' + o.Name,
              value: f + '_' + o.ID,
            };
          }),
        );
      }
    });

    setFilterOptions(filterOptions);
    setIsLoading(false);
  }, [FILTER_TYPES, getFilterOptions]);

  const handleFilterChange = async (e, data) => {
    setActiveFilter(filterOptions.find(f => f.value === data.value));
  };

  const handleMonthClick = month => {
    setDrilldownView(true);
    GetAgingReport(month);
  };

  const handleSort = key => {
    const sortOrder = key === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(key);
    setSortOrder(sortOrder);
  };

  const downloadCSV = () => {
    const blob = new Blob([agingData], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
  };

  const generateTable = () => {
    let rows = agingData.split('\n');
    const headers = rows.shift().split(',');

    // remove empty rows
    rows = rows.filter(l => l.split(',').length === headers.length);

    if (sortField !== null) {
      rows = rows.sort((a, b) => {
        return (
          a.split(',')[sortField].toString().localeCompare(b.split(',')[sortField].toString(), 'en', {
            numeric: true,
          }) * (order === 'asc' ? 1 : -1)
        );
      });
    }

    return (
      <Table celled striped>
        <thead style={{ position: 'sticky', top: 0 }}>
          <tr>
            {headers.map((title, i) => {
              let sortConfig = 'sort';
              if (i === 0) {
                sortConfig = '';
              }

              if (sortField === i) {
                sortConfig = order === 'asc' ? 'sort up' : 'sort down';
              }

              return (
                <th key={i} style={{ overflowX: 'auto', textOverflow: 'unset' }}>
                  {title}
                  <Icon className={sortConfig} onClick={() => handleSort(i)} />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {row.split(',').map((cell, i) => (
                <td key={i} style={{ overflowX: 'auto', textOverflow: 'unset' }}>
                  {i === 1 ? (
                    <Button
                      style={{ padding: '10px' }}
                      className="month-button"
                      content={cell}
                      onClick={() => {
                        !drilldownView && handleMonthClick(cell);
                      }}
                    />
                  ) : (
                    cell
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  useEffect(() => {
    if (!filterOptions.length) {
      GetFilterOptions();
    }
  }, [GetFilterOptions, filterOptions]);

  useEffect(() => {
    GetAgingReport();
  }, [activeFilter, GetAgingReport]);

  return (
    <>
      {error && (
        <div>
          <p>{error.message}</p>
        </div>
      )}

      {!error && (
        <>
          {isLoading ? (
            <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
              <Dimmer active inverted>
                <Loader>Loading</Loader>
              </Dimmer>
            </Segment>
          ) : (
            <div>
              <div className="header-container">
                <h2>{`Aging Report - ${activeFilter.text.replace(/[^-]+ - /, '')}`}</h2>
              </div>
              {agingData && (
                <>
                  <div className="button-row">
                    <div>
                      <Dropdown
                        defaultValue={activeFilter.value}
                        button
                        className="icon"
                        floating
                        labeled
                        header={<Dropdown.Header icon="search" content={FILTER_TYPES.join(', ')} />}
                        icon="filter"
                        options={filterOptions}
                        search
                        text={activeFilter.text}
                        onChange={handleFilterChange}
                      />
                      {drilldownView && <Button content="Back" id="back" onClick={() => GetAgingReport()} />}
                    </div>
                    <Button onClick={downloadCSV}>Download Report</Button>
                  </div>

                  {generateTable()}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AgingReport;
