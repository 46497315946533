import { SET_LAST_UPDATED } from '../../constants/actionTypes';
import { search } from './search';
import { getSearchQuery } from '../../selectors/merchantAdmin';

export const setResourceLastUpdated = () => ({ type: SET_LAST_UPDATED, lastUpdated: new Date().toString() });

export const setLastUpdated = () => async (dispatch, getState) => {
  const searchQuery = getSearchQuery(getState());
  if (searchQuery.length > 2) {
    dispatch(search());
  }
  dispatch(setResourceLastUpdated());
};
