import { SERVER_ENDPOINT } from '../constants';
import { WF_FETCH, WF_CSV_FETCH } from '../constants/actionTypes';
import { setBadAuth } from '../actions';
import { wfUserAgent, isJsonString } from '../helpers';
import { toast } from 'react-toastify';

const wfFetch = async (tokens, method, path, body) => {
  const auth0AccessToken = localStorage.getItem('accessToken');
  const encryptedId = localStorage.getItem('encryptedId') || 'No identifier set';

  const options = {
    method,
    headers: {
      'X-WF-DeviceToken': tokens.deviceToken,
      'X-WF-UserAgent': wfUserAgent(),
      'X-WF-UserId': encryptedId,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth0AccessToken}`,
    },
    credentials: 'include',
  };

  if (typeof body !== 'undefined') {
    // handle form data (image upload)
    if (typeof body.formData !== 'undefined') {
      options.body = body.formData;
      // let browser determine Content-Type and boundary
      delete options.headers['Content-Type'];
    } else {
      options.body = JSON.stringify(body);
    }
  }

  const response = await fetch(`${SERVER_ENDPOINT}${path}`, options);
  const json = await response.json();

  if (!response.ok) {
    const error = {
      statusCode: response.status,
      message: json?.message || json?.error || 'An unknown error occurred.',
    };

    if (typeof json === 'string' && !isJsonString(json)) {
      error.message = json;
    }

    return Promise.reject(error);
  }

  json.statusCode = response.status;
  return json;
};

export default store => next => async action => {
  const { type, method, path, body } = action;
  const { deviceToken } = store.getState().user;
  const tokens = { deviceToken };
  if (type === WF_FETCH) {
    try {
      const json = await wfFetch(tokens, method, path, body);
      return json;
    } catch (error) {
      if (error.statusCode === 403) {
        if (!store.getState().error.badAuth) {
          return next(setBadAuth());
        }
      } else if (error.statusCode === 429) {
        toast.error(error.message);
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  } else if (type === WF_CSV_FETCH) {
    try {
      const res = await wfCSVFetch(tokens, method, path, body);
      return res;
    } catch (error) {
      if (error.statusCode === 403) {
        if (!store.getState().error.badAuth) {
          return next(setBadAuth());
        }
      } else if (error.statusCode === 429) {
        toast.error(error.message);
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  }
  return next(action);
};

const wfCSVFetch = async (tokens, method, path) => {
  const auth0AccessToken = localStorage.getItem('accessToken');
  const encryptedId = localStorage.getItem('encryptedId') || 'No identifier set';

  const options = {
    method,
    headers: {
      'X-WF-DeviceToken': tokens.deviceToken,
      'X-WF-UserAgent': wfUserAgent(),
      'X-WF-UserId': encryptedId,
      Authorization: `Bearer ${auth0AccessToken}`,
    },
    credentials: 'include',
  };

  try {
    const response = await fetch(`${SERVER_ENDPOINT}${path}`, options);
    const json = await response.json();
    json.statusCode = json.statusCode || response.status;

    if (!response.ok) {
      return Promise.reject(json);
    }

    // strip headers besides the content disposition
    const resObj = {
      statusCode: json.statusCode,
      body: json.body,
      headers: {
        'content-disposition': json.headers['content-disposition'],
      },
    };

    return resObj;
  } catch (err) {
    return Promise.reject(err);
  }
};
