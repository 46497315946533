import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'semantic-ui-react';

import Lock from '../Lock/Lock';

import './LockLabel.css';

const LockLabel = ({ label, locks, handleResourceChange, className, disabled }) => {
  return (
    <Label
      content={
        <div className={`lock-label ${className}`}>
          {label}
          <Lock locks={locks} name={label} handleResourceChange={handleResourceChange} disabled={disabled} />
        </div>
      }
      className={disabled ? 'disabled' : ''}
    />
  );
};

LockLabel.propTypes = {
  label: PropTypes.string.isRequired,
  locks: PropTypes.array.isRequired,
  handleResourceChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default LockLabel;
