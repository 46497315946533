import { connect } from 'react-redux';

import { searchMerchants } from '../../../actions/merchantAdmin';

import MerchantSearch from './MerchantSearch';

const mapActionsToProps = {
  searchMerchants,
};

export default connect(null, mapActionsToProps)(MerchantSearch);
