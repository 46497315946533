import React, { useState } from 'react';
import KeywordTargetQueueTable from '../../components/KeywordTargetQueue/KeywordTargetQueueTable';
import { CouponTargetAssignmentStatus } from './types';
import { Dropdown, DropdownItemProps, DropdownProps, Menu } from 'semantic-ui-react';
import './KeywordTargetQueue.scss';

const statusOptions: DropdownItemProps[] = [
  { text: 'Pending', value: 'PENDING', key: 'pending', name: 'Pending' },
  { text: 'Blocked', value: 'BLOCKED', key: 'blocked', name: 'Blocked' },
  { text: 'Ignored', value: 'IGNORED', key: 'ignored', name: 'Ignored' },
  { text: 'Finalized', value: 'FINALIZED', key: 'finalized', name: 'Finalized' },
];

export const KeywordTargetQueue = () => {
  const [activeStatusFilter, setActiveStatusFilter] = useState<CouponTargetAssignmentStatus>('PENDING');

  const handleOnChange = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    const value: CouponTargetAssignmentStatus = data.value as CouponTargetAssignmentStatus;
    setActiveStatusFilter(value);
  };

  return (
    <div style={{ flexGrow: 1, maxWidth: '95vw' }} className="keyword-target-queue">
      <h1 className="keyword-target-queue-title">Keyword Target Queue</h1>
      <div className="keyword-target-queue-header">
        <Menu className="keyword-target-queue-dropdown">
          <Dropdown
            simple
            item
            icon="filter"
            options={statusOptions}
            onChange={handleOnChange}
            value={activeStatusFilter}
          />
        </Menu>
      </div>
      <KeywordTargetQueueTable activeStatusFilter={activeStatusFilter} />
    </div>
  );
};

export default KeywordTargetQueue;
