import React, { useState, useEffect } from 'react';
import CategoryTable from './CategoryTable';
import _ from 'lodash';

import { Button, Grid, Modal, Icon, Header, Search, Form, Input, Tab } from 'semantic-ui-react';
import './Category.css';

const CategoryModal = ({ merchantID, categoryOptions, merchantCategories, updateMerchantCategories, disabled }) => {
  const [pendingChanges, setPendingChanges] = useState({});
  const [disabledButton, setDisabledButton] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [merchantMap, setMerchantMap] = useState({});
  const [newPrimaryCategory, setNewPrimaryCategory] = useState(null);

  useEffect(() => {
    let map = {};

    merchantCategories.forEach(mc => (map[mc.CategoryID] = mc));
    setMerchantMap(map);
  }, [merchantCategories, categoryOptions]);

  const handleCheck = (e, category) => {
    let temp = pendingChanges;

    if (category.checked) {
      temp[category.id] = category;
    } else {
      delete temp[category.id];
    }

    setPendingChanges(temp);

    // prevent mixing add/remove operations
    if (Object.keys(temp).length === 1) {
      setDisabledButton(category.action);
    } else if (Object.keys(temp).length === 0) {
      setDisabledButton('');
    } else if (category.action === disabledButton) {
      setDisabledButton(category.action);
    }
  };

  const add = async () => {
    const updated = merchantCategories.concat(
      Object.keys(pendingChanges).map(k => {
        return {
          MerchantID: Number(merchantID),
          CategoryID: pendingChanges[k].id,
          Source: 'ADMIN',
          IsPrimary: false,
        };
      }),
    );

    setIsLoading(true);
    try {
      await updateMerchantCategories(updated);
    } catch (error) {
      Promise.reject(error);
    }
    setIsLoading(false);
    setPendingChanges({});
    setDisabledButton('');
  };

  const remove = async () => {
    let updated = merchantCategories.slice();

    Object.keys(pendingChanges).forEach(k => {
      let idx = updated.findIndex(mc => mc.CategoryID === pendingChanges[k].id);
      updated.splice(idx, 1);
    });

    setIsLoading(true);
    try {
      await updateMerchantCategories(updated);
    } catch (error) {
      Promise.reject(error);
    }
    setIsLoading(false);
    setPendingChanges({});
    setDisabledButton('');
  };

  const search = (e, search) => {
    setSearchValue(search.value.toLowerCase());
  };

  const handleSavePrimary = async () => {
    let updated = merchantCategories.slice();

    const currentPrimary = updated.find(mc => mc.CategoryID === primaryCategory?.ID);
    if (currentPrimary !== undefined) {
      currentPrimary.IsPrimary = false;
    }

    if (newPrimaryCategory !== undefined) {
      newPrimaryCategory.IsPrimary = true;
      newPrimaryCategory.Source = 'ADMIN';
    }

    setIsLoading(true);
    try {
      await updateMerchantCategories(updated);
    } catch (error) {
      Promise.reject(error);
    }
    setIsLoading(false);
  };

  const addable = categoryOptions
    .filter(c => !merchantCategories.map(mc => mc.CategoryID).includes(c.ID))
    .sort((a, b) => a.Name.localeCompare(b.Name));

  const added = merchantCategories
    .map(mc => categoryOptions.find(c => c.ID === mc.CategoryID))
    .sort((a, b) => a.Name.localeCompare(b.Name));

  const primaryCategory = categoryOptions.find(c => c.ID === merchantCategories.find(mc => mc.IsPrimary)?.CategoryID);

  const options = merchantCategories.map(mc => {
    return {
      value: mc.CategoryID,
      text: categoryOptions.find(c => c.ID === mc.CategoryID)?.Name,
    };
  });

  const panes = [
    {
      menuItem: 'Edit Primary',
      render: () => (
        <Grid>
          <Grid.Column width={6}>
            <Form onSubmit={handleSavePrimary}>
              <Form.Select
                label="Primary Category"
                clearable
                search
                selectOnBlur={false}
                options={options}
                defaultValue={primaryCategory?.ID}
                noResultsMessage="No categories have been added to this merchant yet"
                onChange={(e, target) => setNewPrimaryCategory(merchantMap[target.value])}
                disabled={disabled}
              />
              <Form.Button
                loading={isLoading}
                type="submit"
                disabled={
                  disabled || newPrimaryCategory === null || primaryCategory?.ID === newPrimaryCategory?.CategoryID
                }
              >
                Save
              </Form.Button>
            </Form>
          </Grid.Column>
        </Grid>
      ),
    },
    {
      menuItem: 'Add/Remove',
      render: () => (
        <Grid>
          <Grid.Row>
            <Grid.Column width={3}>
              <Search placeholder="Search for a category" onSearchChange={_.debounce(search, 500)} open={false} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <Header as="h2">Added</Header>
              <CategoryTable
                action="add"
                categories={added}
                handleCheck={handleCheck}
                disabled={disabled || disabledButton === 'remove'}
                searchValue={searchValue}
              />
            </Grid.Column>

            <Grid.Column className="buttons" width={2}>
              <Button
                color="green"
                style={{ marginBottom: '20px' }}
                onClick={add}
                disabled={disabled || disabledButton === 'add'}
                loading={isLoading}
              >
                <Icon name="angle double left" />
                Add
              </Button>
              <Button
                color="red"
                onClick={remove}
                disabled={disabled || disabledButton === 'remove'}
                loading={isLoading}
              >
                Remove
                <Icon name="angle double right" />
              </Button>
            </Grid.Column>

            <Grid.Column width={7}>
              <Header as="h2">Addable</Header>
              <CategoryTable
                action="remove"
                categories={addable}
                handleCheck={handleCheck}
                disabled={disabled || disabledButton === 'add'}
                searchValue={searchValue}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ),
    },
  ];

  return (
    <Modal
      className="centered"
      closeIcon={true}
      size="fullscreen"
      trigger={
        <div className="primary-category">
          <Input
            fluid
            value={primaryCategory?.Name || 'N/A'}
            label="Primary Category"
            action="Edit"
            disabled={disabled}
          />
        </div>
      }
    >
      <Modal.Header>Category Manager</Modal.Header>
      <Modal.Content>
        <Tab renderActiveOnly={true} menu={{ fluid: true, tabular: true }} panes={panes} />
      </Modal.Content>
    </Modal>
  );
};

export default CategoryModal;
