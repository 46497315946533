import { connect } from 'react-redux';
import TechSupport from './TechSupport';

import { getCloudProfile } from '../../actions/techSupport/cloudProfile';

const mapActionsToProps = {
  getCloudProfile,
};

export default connect(null, mapActionsToProps)(TechSupport);
