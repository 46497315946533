import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Icon } from 'semantic-ui-react';

import './SearchResult.css';

const icons = {
  keyword: 'tag',
  merchant: 'cube',
  network_merchant: 'cubes',
  network_merchant_hotel: 'home',
};

const SearchResult = ({ type, kind, value, disabled }) => {
  return (
    <Grid className="searchResult" columns="equal" verticalAlign="middle" title={type === kind ? type : kind}>
      <Grid.Row className={disabled ? 'disabled' : null}>
        <Grid.Column width={1}>
          <Icon name={icons[type]} />
        </Grid.Column>
        <Grid.Column>{value}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SearchResult.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SearchResult;
