import React, { useState } from 'react';
import { Segment, Input as InputUI, Icon, Button } from 'semantic-ui-react';

export const NewTarget = ({
  keywordId,
  createKeywordTarget,
  isValidSelector,
  isValidTarget,
  isLoading,
  doesUserHaveExtensionInstalled,
  wasTested,
  sendCouponDataToExtension,
}) => {
  const [nextTarget, setNextTarget] = useState({
    KeywordID: keywordId,
    Input: '',
    Price: '',
    Submit: '',
    Remove: '',
    Timeout: 0,
    Error: '',
    Before: '',
    CCType: '',
    CCNumber: '',
    CCCSC: '',
    CCName: '',
    CCGivenName: '',
    CCAdditionalName: '',
    CCFamilyName: '',
    CCExp: '',
    CCExpMonth: '',
    CCExpYear: '',
    Disabled: false,
    AttemptTimeout: 0,
    Attempts: 0,
  });

  const {
    Input,
    Price,
    Submit,
    Remove,
    Timeout,
    Error: ErrorValue,
    Before,
    CCType,
    CCNumber,
    CCCSC,
    CCName,
    CCGivenName,
    CCAdditionalName,
    CCFamilyName,
    CCExp,
    CCExpMonth,
    CCExpYear,
    Disabled,
    AttemptTimeout,
    Attempts,
  } = nextTarget;

  const handleChange = (_, { name, value }) => {
    const target = { ...nextTarget };
    target[name] = value;
    setNextTarget(target);
  };

  const handleCreate = () => {
    if (!isValidTarget(nextTarget)) return;
    createKeywordTarget(keywordId, nextTarget);
  };

  return (
    <Segment.Group>
      <Segment>
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Input'}
          name={'Input'}
          value={Input}
          error={!isValidSelector(Input) || !Input}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Price'}
          name={'Price'}
          value={Price}
          error={!isValidSelector(Price) || !Price}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Submit'}
          name={'Submit'}
          value={Submit}
          error={!isValidSelector(Submit)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Remove'}
          name={'Remove'}
          value={Remove}
          error={!isValidSelector(Remove)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Before'}
          name={'Before'}
          value={Before}
          error={!isValidSelector(Before)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Timeout'}
          name={'Timeout'}
          type="number"
          error={Number(Timeout) < 0}
          value={Timeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Error'}
          name={'Error'}
          value={ErrorValue}
          error={!isValidSelector(ErrorValue)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Type'}
          name={'CCType'}
          value={CCType}
          error={!isValidSelector(CCType)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Number'}
          name={'CCNumber'}
          value={CCNumber}
          error={!isValidSelector(CCNumber)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card CVV'}
          name={'CCCSC'}
          value={CCCSC}
          error={!isValidSelector(CCCSC)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={"Credit Card Holder's Name"}
          name={'CCName'}
          value={CCName}
          error={!isValidSelector(CCName)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card First Name'}
          name={'CCGivenName'}
          value={CCGivenName}
          error={!isValidSelector(CCGivenName)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Middle Name'}
          name={'CCAdditionalName'}
          value={CCAdditionalName}
          error={!isValidSelector(CCAdditionalName)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Last Name'}
          name={'CCFamilyName'}
          value={CCFamilyName}
          error={!isValidSelector(CCFamilyName)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Exp Date'}
          name={'CCExp'}
          value={CCExp}
          error={!isValidSelector(CCExp)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Exp Month'}
          name={'CCExpMonth'}
          value={CCExpMonth}
          error={!isValidSelector(CCExpMonth)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Credit Card Exp Year'}
          name={'CCExpYear'}
          value={CCExpYear}
          error={!isValidSelector(CCExpYear)}
        />
        <InputUI
          fluid
          disabled={true}
          label={'Disabled'}
          name={'Disabled'}
          value={Disabled}
          error={!isValidSelector(Disabled)}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Attempt Timeout'}
          name={'Attempt Timeout'}
          type="number"
          error={Number(AttemptTimeout) < 0}
          value={AttemptTimeout}
        />
        <InputUI
          fluid
          disabled={isLoading}
          onChange={handleChange}
          label={'Attempts'}
          name={'Attempts'}
          type="number"
          error={Number(Attempts) < 0}
          value={Attempts}
        />
        <div className="flex-right">
          <Button disabled={isLoading || !isValidTarget(nextTarget)} positive onClick={handleCreate}>
            Create
          </Button>
          <Button
            onClick={() => sendCouponDataToExtension(nextTarget)}
            disabled={!doesUserHaveExtensionInstalled || isLoading || !isValidTarget(nextTarget)}
            color={wasTested ? 'green' : 'blue'}
            title={
              doesUserHaveExtensionInstalled
                ? 'This will send the target data and some test coupon codes to your extension'
                : 'This option is not currently available, we have not detected that you have a browser extension installed that can support our coupon testing data'
            }
          >
            {wasTested ? <Icon name="check" className="m-0" /> : 'Test'}
          </Button>
        </div>
      </Segment>
    </Segment.Group>
  );
};
