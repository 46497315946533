import React from 'react';
import { Label, Modal } from 'semantic-ui-react';
import KeywordDetail from '../Keyword/KeywordDetail/KeywordDetail';
import { Keyword } from './types';
import './NetworkMerchantOnboardingModal.scss';

interface NetworkMerchantOnboardingKeywordListProps {
  keywords: Keyword[];
  keywordError: string;
  handleUpsertNetworkMerchantKeyword: (keyword: Keyword) => void;
  setKeywordError: (error: string) => void;
}

const NetworkMerchantOnboardingKeywordList: React.FC<NetworkMerchantOnboardingKeywordListProps> = ({
  keywords,
  keywordError,
  handleUpsertNetworkMerchantKeyword,
  setKeywordError,
}) => {
  return (
    <div className="network-merchant-onboarding-keywords-list">
      {keywords.map(keyword => {
        return (
          <Modal
            className="update-keywords"
            key={keyword.KeywordID}
            onClose={() => setKeywordError('')}
            trigger={
              <div className="network-merchant-onboarding-keyword">
                <Label>
                  {keyword.KeywordName}
                  <span> ({keyword.Kind})</span>
                </Label>
              </div>
            }
          >
            <Modal.Header>Update Keyword</Modal.Header>
            <Modal.Content>
              <>
                <h3> Keyword (ID: {keyword.KeywordID})</h3>
                {keywordError ? (
                  <>
                    <p>An error occurred. Please try again in a few minutes.</p>
                    <p>{keywordError}</p>
                  </>
                ) : (
                  <KeywordDetail
                    keyword={keyword}
                    updateKeyword={handleUpsertNetworkMerchantKeyword}
                    isMerchantKeyword={false}
                  />
                )}
              </>
            </Modal.Content>
          </Modal>
        );
      })}
    </div>
  );
};

export default NetworkMerchantOnboardingKeywordList;
