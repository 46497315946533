import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './Target.css';
import { Segment, Icon, Input as InputUI, Button, Dropdown } from 'semantic-ui-react';
import { createSemanticUIOption } from 'helpers';

export const Target = ({
  target,
  isLoading,
  isValidSelector,
  isValidTarget,
  updateKeywordTarget,
  doesUserHaveExtensionInstalled,
  wasTested,
  isDisabled,
  sendCouponDataToExtension,
  viewOnly,
}) => {
  const [nextTarget, setNextTarget] = useState({ ...target });
  const [isFocused, setFocused] = useState(false);

  const canEdit = useSelector(state => state.user.canEdit);
  const isViewOnly = viewOnly ?? !canEdit;
  const {
    ID,
    Input,
    Price,
    Submit,
    Remove,
    Timeout,
    Error: ErrorValue,
    Before,
    CCType,
    CCNumber,
    CCCSC,
    CCName,
    CCGivenName,
    CCAdditionalName,
    CCFamilyName,
    CCExp,
    CCExpMonth,
    CCExpYear,
    AttemptTimeout,
    Attempts,
    CheckoutURLs,
    ConfirmURLs
  } = nextTarget;

  const handleChange = (_, { name, value }) => {
    setNextTarget(prev => ({ ...prev, [name]: value }));
  };

  const handleDropdownChange = (name, value) => {
    setNextTarget(prev => ({ ...prev, [name]: value }));
  };

  const handleDisable = () => {
    setNextTarget(prev => {
      const newTarget = { ...prev, Disabled: !prev.Disabled };
      if (isValidTarget(newTarget)) {
        updateKeywordTarget(newTarget.ID, newTarget);
      }
      return newTarget;
    });
  };

  useEffect(() => {
    if (!isFocused && !_.isEqual(target, nextTarget)) {
      if (isValidTarget(nextTarget)) {
        updateKeywordTarget(nextTarget.ID, nextTarget);
      } else {
        setNextTarget({ ...target });
      }
    }
  }, [isFocused, target, nextTarget, isValidTarget, updateKeywordTarget]);

  useEffect(() => {
    setNextTarget(prev => ({ ...prev, Disabled: isDisabled }));
  }, [isDisabled]);

  return (
    <Segment.Group>
      <Segment>
        <h3>
          <Icon name="target" />
          Keyword Target (ID: {ID})
        </h3>
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Input'}
          name={'Input'}
          value={Input}
          error={!isValidSelector(Input) || !Input}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Price'}
          name={'Price'}
          value={Price}
          error={!isValidSelector(Price) || !Price}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Submit'}
          name={'Submit'}
          value={Submit}
          error={!isValidSelector(Submit)}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Remove'}
          name={'Remove'}
          value={Remove}
          error={!isValidSelector(Remove)}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Before'}
          name={'Before'}
          value={Before}
          error={!isValidSelector(Before)}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Timeout'}
          name={'Timeout'}
          type="number"
          error={Number(Timeout) < 0}
          value={Timeout}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Error'}
          name={'Error'}
          value={ErrorValue}
          error={!isValidSelector(ErrorValue)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Type'}
          name={'CCType'}
          value={CCType}
          error={!isValidSelector(CCType)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Number'}
          name={'CCNumber'}
          value={CCNumber}
          error={!isValidSelector(CCNumber)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card CVV'}
          name={'CCCSC'}
          value={CCCSC}
          error={!isValidSelector(CCCSC)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={"Credit Card Holder's Name"}
          name={'CCName'}
          value={CCName}
          error={!isValidSelector(CCName)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card First Name'}
          name={'CCGivenName'}
          value={CCGivenName}
          error={!isValidSelector(CCGivenName)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Middle Name'}
          name={'CCAdditionalName'}
          value={CCAdditionalName}
          error={!isValidSelector(CCAdditionalName)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Last Name'}
          name={'CCFamilyName'}
          value={CCFamilyName}
          error={!isValidSelector(CCFamilyName)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Exp Date'}
          name={'CCExp'}
          value={CCExp}
          error={!isValidSelector(CCExp)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Exp Month'}
          name={'CCExpMonth'}
          value={CCExpMonth}
          error={!isValidSelector(CCExpMonth)}
        />
        <InputUI
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          fluid
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Credit Card Exp Year'}
          name={'CCExpYear'}
          value={CCExpYear}
          error={!isValidSelector(CCExpYear)}
        />
        <InputUI
          fluid
          disabled={true}
          label={'Disabled'}
          name={'Disabled'}
          value={isDisabled.toString()}
          error={!isValidSelector(isDisabled)}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Attempt Timeout'}
          name={'Attempt Timeout'}
          type="number"
          error={Number(AttemptTimeout) < 0}
          value={AttemptTimeout}
        />
        <InputUI
          fluid
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isLoading || isViewOnly}
          onChange={handleChange}
          label={'Attempts'}
          name={'Attempts'}
          type="number"
          error={Number(Attempts) < 0}
          value={Attempts}
        />
        <div className="dropdown-container">
          <label className="dropdown-label">Checkout URLs</label>
          <Dropdown
            className="dropdown-unrounded-left"
            name="CheckoutURLs"
            options={CheckoutURLs.map(createSemanticUIOption)}
            noResultsMessage={null}
            value={CheckoutURLs}
            onChange={(_, data) => handleDropdownChange("CheckoutURLs", data.value)}
            disabled={!canEdit}
            fluid
            multiple
            selection
            allowAdditions
            search
          />
        </div>
        <div className="dropdown-container">
          <label className="dropdown-label">Confirm URLs</label>
          <Dropdown
            className="dropdown-unrounded-left"
            name="ConfirmURLs"
            options={ConfirmURLs.map(createSemanticUIOption)}
            noResultsMessage={null}
            value={ConfirmURLs}
            onChange={(_, data) => handleDropdownChange("ConfirmURLs", data.value)}
            disabled={!canEdit}
            fluid
            multiple
            selection
            allowAdditions
            search
          />
        </div>
        {!isViewOnly && (
          <div className="flex-right">
            <Button
              disabled={isLoading || !isValidTarget(nextTarget)}
              color={isDisabled ? 'red' : 'green'}
              onClick={handleDisable}
            >
              {isDisabled ? 'Disabled' : 'Enabled'}
            </Button>
            <Button
              onClick={() => sendCouponDataToExtension(target)}
              disabled={!doesUserHaveExtensionInstalled || isLoading}
              color={wasTested ? 'green' : 'blue'}
              title={
                doesUserHaveExtensionInstalled
                  ? 'This will send the target data and some test coupon codes to your extension'
                  : 'This option is not currently available, we have not detected that you have a browser extension installed that can support our coupon testing data'
              }
            >
              {wasTested ? <Icon name="check" className="m-0" /> : 'Test'}
            </Button>
          </div>
        )}
      </Segment>
    </Segment.Group>
  );
};
