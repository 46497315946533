import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from 'semantic-ui-react';

const KeywordSearchResult = ({ resourceid, type, kind, value, disabled, handleResultSelect }) => {
  return (
    <Grid
      className="searchResult"
      columns="equal"
      verticalAlign="middle"
      title={type === kind ? type : kind}
      onClick={handleResultSelect}
    >
      <Grid.Row className={disabled ? 'disabled' : null}>
        <Grid.Column>ID: {resourceid}</Grid.Column>
        <Grid.Column>{value}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

KeywordSearchResult.propTypes = {
  resourceid: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleResultSelect: PropTypes.func.isRequired,
};

export default KeywordSearchResult;
