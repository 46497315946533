import React, { Component } from 'react';
import { Button, Table, Ref } from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './OrdinalRankTable.css';

export default class OrdinalRankTable extends Component {
  getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging && 'lightblue',
    ...draggableStyle,
  });
  handleDragEnd = ({ source, destination }) => {
    if (!source || !destination) return;
    if (source.index === destination.index) return;
    this.props.setNewOrdinal(source.index, destination.index);
  };
  render() {
    const { networkMerchantKeywordsWithOrdinal, removeFromOrdinal } = this.props;
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Network Merchant ID</Table.HeaderCell>
              <Table.HeaderCell>Network Merchant Name</Table.HeaderCell>
              <Table.HeaderCell>Kind</Table.HeaderCell>
              <Table.HeaderCell>Ordinal Rank</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Droppable droppableId="tableBody">
            {(provided, snapshot) => (
              <Ref innerRef={provided.innerRef}>
                <Table.Body {...provided.droppableProps}>
                  {networkMerchantKeywordsWithOrdinal.map((nmk, i) => (
                    <Draggable key={i} draggableId={i.toString()} index={i}>
                      {(provided, snapshot) => (
                        <Ref innerRef={provided.innerRef}>
                          <Table.Row
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <Table.Cell>{nmk.ID}</Table.Cell>
                            <Table.Cell>{nmk.NetworkMerchantID}</Table.Cell>
                            <Table.Cell>{nmk.NetworkMerchantName}</Table.Cell>
                            <Table.Cell>{nmk.Kind}</Table.Cell>
                            <Table.Cell>{nmk.Ordinal}</Table.Cell>
                            <Table.Cell>
                              <Button onClick={() => removeFromOrdinal(nmk)}>Remove</Button>
                            </Table.Cell>
                          </Table.Row>
                        </Ref>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Table.Body>
              </Ref>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    );
  }
}
