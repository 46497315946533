import { connect } from 'react-redux';

import MerchantStats from './MerchantStats';
import { getMerchantStats } from 'actions/merchantStats/merchantStats';

const mapStateToProps = state => ({
  csv: state.csv,
});

const mapActionsToProps = {
  getMerchantStats,
};

export default connect(mapStateToProps, mapActionsToProps)(MerchantStats);
