import React, { KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { getUserCanEdit } from 'selectors/user';
import { Table, Form } from 'semantic-ui-react';
import Lock from '../Lock/Lock';
import { Commission } from './types';
import NetworkMerchantOnboardingCommissionsDisplayName from './NetworkMerchantOnboardingCommissionsDisplayName';
import './NetworkMerchantOnboardingModal.scss';

interface NetworkMerchantOnboardingCommissionsTableProps {
  isDQContractor: boolean;
  commissions: Commission[];
  handleUpdateCommission: (Commission: Commission) => void;
}

const NetworkMerchantOnboardingCommissionsTable: React.FC<NetworkMerchantOnboardingCommissionsTableProps> = ({
  isDQContractor,
  commissions,
  handleUpdateCommission,
}) => {
  const canEdit = useSelector(state => getUserCanEdit(state));

  // To prevent random re-organization of items in list when toggling on and off
  let disabledItems = commissions.filter(item => item.Disabled === true);
  let activeItems = commissions.filter(item => item.Disabled === false);

  // Sorting disabledItems and activeItems in ascending order based on commission ID
  disabledItems.sort((a, b) => {
    return a.ID - b.ID;
  });
  activeItems.sort((a, b) => {
    return a.ID - b.ID;
  });

  const sortedCommissions = activeItems.concat(disabledItems);

  const handleSubmit = (e: KeyboardEvent<HTMLInputElement>, commission: Commission, displayName: string) => {
    if (e.key === 'Enter') {
      const updatedCommission = {
        ...commission,
        DisplayName: displayName.trim(),
      };

      if (commission.DisplayName !== updatedCommission.DisplayName) {
        handleUpdateCommission(updatedCommission);
      }
    }
  };

  return (
    <Table className="network-merchant-onboarding-commissions" singleLine celled compact striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Kind</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Display Name</Table.HeaderCell>
          {canEdit && <Table.HeaderCell>Status</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedCommissions.map(commission => (
          <Table.Row key={commission.ID} className={commission.Disabled ? 'disable' : ''}>
            <Table.Cell>
              <p className="header-label">ID: </p>
              {commission.ID}
            </Table.Cell>
            <Table.Cell>
              <p className="header-label">Name: </p>
              {commission.Name}
            </Table.Cell>
            <Table.Cell>
              <p className="header-label">Kind: </p>
              {commission.Kind}
            </Table.Cell>
            <Table.Cell>
              <p className="header-label">Amount: </p>
              {commission.Amount}
            </Table.Cell>
            <Table.Cell selectable className="interactive">
              <p className="header-label">Display Name: </p>
              {canEdit ? (
                <NetworkMerchantOnboardingCommissionsDisplayName commission={commission} handleSubmit={handleSubmit} />
              ) : (
                commission.DisplayName
              )}
              <Lock
                commission={commission}
                locks={commission.Locks}
                label="DisplayName"
                name="DisplayName"
                handleResourceChange={handleUpdateCommission}
                disabled={!canEdit}
              />
            </Table.Cell>
            {canEdit && (
              <Table.Cell className="status">
                <p className="header-label">Status: </p>
                <Form.Checkbox
                  disabled={isDQContractor}
                  defaultChecked={!commission.Disabled}
                  onChange={() =>
                    handleUpdateCommission({
                      ...commission,
                      Disabled: !commission.Disabled,
                    })
                  }
                ></Form.Checkbox>
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default NetworkMerchantOnboardingCommissionsTable;
