import { connect } from 'react-redux';

import { addMerchant } from '../../../../actions/merchantAdmin';

import AddMerchant from './AddMerchant';

const mapActionsToProps = {
  addMerchant,
};

export default connect(null, mapActionsToProps)(AddMerchant);
