import { connect } from 'react-redux';

import { setLastUpdated } from '../../actions/merchantAdmin';
import { getUserCanEdit } from 'selectors/user';

import NetworkMerchantHotel from './NetworkMerchantHotel';

const mapStateToProps = state => ({
  canEdit: getUserCanEdit(state),
});

const mapActionsToProps = {
  setLastUpdated,
};

export default connect(mapStateToProps, mapActionsToProps)(NetworkMerchantHotel);
