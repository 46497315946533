import { combineReducers } from 'redux';

import search from './search';
import resource from './resource';
import network from 'redux/store/network/reducer';

export default combineReducers({
  search,
  resource,
  network,
});
