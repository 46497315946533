import React from 'react';
import { useParams } from 'react-router-dom';
import Job from 'components/Reconciliation/ReconciliationJob';

const ReconciliationJob = ({ getReconcileJob, getReconcileJobMerchants }) => {
  const { id } = useParams();
  return (
    <div style={{ flexGrow: 1, maxWidth: '1200px' }}>
      <Job id={id} getReconcileJob={getReconcileJob} getReconcileJobMerchants={getReconcileJobMerchants} />
    </div>
  );
};

export default ReconciliationJob;
