import React, { useEffect, useState, useCallback, useRef } from 'react';
import { matchPath } from 'react-router';
import { NewTarget } from 'components/KeywordTarget/NewTarget';
import { Target } from 'components/KeywordTarget/Target';
import SearchV2 from 'components/SearchV2/SearchV2';
import { CHROME } from 'helpers/constants';
import { dqx } from 'helpers/dqx';
import { useInterval } from 'hooks/useInterval';
import { useSelector } from 'react-redux';
import { Grid, Segment, Icon, Button } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import './KeywordTargets.css';

const KeywordTargets = ({
  match,
  location,
  getKeywordTargetsByKeywordID,
  addKeywordTargetByKeywordID,
  updateKeywordTargetByID,
  getKeywordNetworkMerchants,
  getCoupons,
}) => {
  const parsedMatch = matchPath(location.pathname, {
    path: '/keyword-targets/:keywordId',
    exact: true,
    strict: false,
  });

  const [keyword, setKeyword] = useState(null);
  const [targets, setTargets] = useState([]);
  const [codes, setCodes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const [successTestingTargetID, setTestingTargetID] = useState(null);

  const [doesUserHaveExtensionInstalled, setUserHaveExtensionInstalled] = useState(false);
  const [doesUserHaveDQXInstalled, setUserHaveDQXInstalled] = useState(false);
  const [isDQXProcessing, setDQXProcessing] = useState(false);

  const canEdit = useSelector(state => state.user.canEdit);

  useEffect(() => {
    if (typeof window.chrome !== 'undefined' && typeof window.chrome.runtime !== 'undefined') {
      const data = {
        status: CHROME.PING,
      };
      window.chrome.runtime.sendMessage(CHROME.WF_EXTENSION_ID, data, response => {
        if (response?.status === CHROME.SUCCESS) {
          setUserHaveExtensionInstalled(true);
        }
      });
    }
  }, []);

  const getCodes = useCallback(
    async networkMerchantKeywords => {
      if (!networkMerchantKeywords?.length) {
        return setCodes([]);
      }
      const networkMerchantIDs = [...new Set(networkMerchantKeywords.map(nmk => nmk.NetworkMerchantID))];
      try {
        let codes = await Promise.all(networkMerchantIDs.map(id => getCoupons(id)));
        codes = codes.filter(c => !!c.length);
        if (!codes.length) {
          return setCodes([]);
        }
        codes = codes.reduce((acc, cur) => [...acc, ...cur], []).filter(v => !!v.Code);
        if (!codes.length) {
          return setCodes([]);
        }
        setCodes(
          codes.map(c => ({
            ID: c.ID,
            Code: c.Code,
          })),
        );
      } catch (err) {
        console.error(err);
      }
    },
    [getCoupons],
  );

  useEffect(() => {
    (async () => {
      try {
        const isInstalled = await dqx.isInstalled();
        setUserHaveDQXInstalled(isInstalled);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useInterval(() => {
    (async () => {
      const keywordId = Number(parsedMatch?.params?.keywordId || 0);
      if (!doesUserHaveDQXInstalled || isDQXProcessing || isCreating || isLoading || !keywordId || isNaN(keywordId)) {
        return;
      }
      setDQXProcessing(true);
      const targets = await dqx.getTargetsInQueue(keywordId);
      for (const target of targets) {
        if (!isValidTarget(target)) {
          await dqx.completeTargetInQueue(keywordId, 0, target);
          continue;
        }
        const targetToCreate = { ...target, KeywordID: keywordId };
        const createdTargetID = await createKeywordTarget(keywordId, targetToCreate);
        await dqx.completeTargetInQueue(keywordId, createdTargetID?.ID ?? 0, target);
      }
      setDQXProcessing(false);
    })();
  }, 500);

  useEffect(() => {
    setTimeout(() => {
      setTestingTargetID(null);
    }, 3000);
  }, [successTestingTargetID]);

  const parsedKeywordId = parsedMatch?.params?.keywordId || 0;
  const prevKeywordIdRef = useRef();
  useEffect(() => {
    const keywordId = parsedKeywordId;
    if (!keywordId || keywordId === prevKeywordIdRef.current) return;
    setLoading(true);
    setCreating(false);
    (async () => {
      try {
        const networkMerchantKeywords = await getKeywordNetworkMerchants(keywordId);
        if (networkMerchantKeywords.length) {
          getCodes(networkMerchantKeywords);
          setKeyword({
            ID: keywordId,
            Name: networkMerchantKeywords?.[0]?.KeywordName || '',
          });
        } else {
          setCodes([]);
        }
        try {
          const newTargets = await getKeywordTargetsByKeywordID(keywordId);
          if (!isEqual(targets, newTargets)) {
            setTargets(newTargets);
          }
        } catch (err) {
          console.error(err);
          if (targets.length) {
            setTargets([]);
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    })();
    prevKeywordIdRef.current = keywordId;
  }, [parsedKeywordId, getCodes, getKeywordNetworkMerchants, getKeywordTargetsByKeywordID, targets]);

  const sendCouponDataToExtension = target => {
    if (!doesUserHaveExtensionInstalled) return;
    const domain = keyword?.Name;
    if (!domain) return;
    if (typeof window.chrome === 'undefined' || typeof window.chrome?.runtime === 'undefined') {
      return;
    }
    setLoading(true);
    target = { ...target };

    target.Timeout = Number(target.Timeout);
    target.AttemptTimeout = Number(target.AttemptTimeout);
    target.Attempts = Number(target.Attempts);

    if (typeof target.ID === 'undefined') {
      target.ID = 0;
    }
    delete target.KeywordID;
    const data = {
      status: CHROME.SET_COUPON_TEST_DATA,
      payload: {
        data: {
          WasFound: true,
          Targets: [target],
          Codes: codes.length ? codes : new Array(3).fill({ ID: 0, Code: 'TEST' }),
        },
        domain,
      },
    };
    window.chrome.runtime.sendMessage(CHROME.WF_EXTENSION_ID, data, response => {
      if (response?.status === CHROME.SUCCESS) {
        setTestingTargetID(target.ID);
      }
      setLoading(false);
    });
  };

  const isValidSelector = selector => {
    if (!selector) return true;
    try {
      document.querySelector(selector);
      return true;
    } catch (_) {
      return false;
    }
  };

  const isValidTarget = target => {
    return (
      isValidSelector(target.Input) &&
      !!target.Input &&
      isValidSelector(target.Price) &&
      !!target.Price &&
      isValidSelector(target.Submit) &&
      isValidSelector(target.Remove) &&
      isValidSelector(target.ErrorValue) &&
      isValidSelector(target.Before) &&
      isValidSelector(target.CCType) &&
      isValidSelector(target.CCNumber) &&
      isValidSelector(target.CCCSC) &&
      isValidSelector(target.CCName) &&
      isValidSelector(target.CCGivenName) &&
      isValidSelector(target.CCAdditionalName) &&
      isValidSelector(target.CCFamilyName) &&
      isValidSelector(target.CCExp) &&
      isValidSelector(target.CCExpMonth) &&
      isValidSelector(target.CCExpYear) &&
      Number(target.Timeout) >= 0 &&
      isValidSelector(target.Disabled) &&
      Number(target.AttemptTimeout) >= 0 &&
      Number(target.Attempts) >= 0
    );
  };
  const updateKeywordTarget = async (targetID, target) => {
    setLoading(true);
    target = { ...target };

    target.Timeout = Number(target.Timeout);
    target.KeywordID = Number(target.KeywordID);
    target.AttemptTimeout = Number(target.AttemptTimeout);
    target.Attempts = Number(target.Attempts);

    await updateKeywordTargetByID(targetID, target);
    const keywordId = parsedMatch?.params?.keywordId;
    if (!keywordId) {
      setLoading(false);
      return;
    }
    try {
      const targets = await getKeywordTargetsByKeywordID(keywordId);
      setTargets(targets);
    } catch (err) {
      console.error(err);
      setTargets([]);
    }
    setLoading(false);
  };

  const createKeywordTarget = async (keywordID, target) => {
    setLoading(true);
    target = { ...target };

    target.Timeout = Number(target.Timeout);
    target.KeywordID = Number(target.KeywordID);
    target.AttemptTimeout = Number(target.AttemptTimeout);
    target.Attempts = Number(target.Attempts);

    // This returns an object with ID property, not a number
    const createdTargetID = await addKeywordTargetByKeywordID(keywordID, target);
    const keywordId = parsedMatch?.params?.keywordId;
    if (!keywordId) {
      setLoading(false);
      setCreating(false);
      return createdTargetID;
    }
    try {
      const targets = await getKeywordTargetsByKeywordID(keywordId);
      setTargets(targets);
    } catch (err) {
      console.error(err);
      setTargets([]);
    }
    setLoading(false);
    setCreating(false);
    return createdTargetID;
  };

  return (
    <div className="merchant-admin">
      <h1>Keyword Targets</h1>
      <Grid columns="equal">
        <Grid.Column width="4">
          <SearchV2 />
        </Grid.Column>
        {match.isExact && (
          <Grid.Column>
            Keyword Targets are web page metadata we use to target elements on the page when auto applying coupons.
          </Grid.Column>
        )}
        {!match.isExact && (
          <Grid.Column>
            {keyword && (
              <Segment.Group>
                <Segment>
                  <div className="flex-between">
                    <h3 className="m-0">
                      <Icon name="tag" />
                      {keyword.Name} (ID: {keyword.ID}) (Testable Codes: {codes.length})
                    </h3>
                    {canEdit && (
                      <Button
                        color={isCreating ? 'red' : 'green'}
                        disabled={isLoading}
                        onClick={() => setCreating(v => !v)}
                      >
                        {isCreating ? <Icon name="minus" /> : <Icon name="plus" />}
                        {isCreating ? 'Cancel' : 'Create'}
                      </Button>
                    )}
                  </div>
                  {isCreating && (
                    <NewTarget
                      keywordId={keyword.ID}
                      createKeywordTarget={createKeywordTarget}
                      isLoading={isLoading}
                      isValidSelector={isValidSelector}
                      isValidTarget={isValidTarget}
                      doesUserHaveExtensionInstalled={doesUserHaveExtensionInstalled}
                      wasTested={successTestingTargetID === 0}
                      sendCouponDataToExtension={sendCouponDataToExtension}
                    />
                  )}
                </Segment>
              </Segment.Group>
            )}
            {targets.map(t => (
              <Target
                key={t.ID}
                target={t}
                isLoading={isLoading}
                isValidSelector={isValidSelector}
                isValidTarget={isValidTarget}
                updateKeywordTarget={updateKeywordTarget}
                doesUserHaveExtensionInstalled={doesUserHaveExtensionInstalled}
                wasTested={t.ID === successTestingTargetID}
                isDisabled={t.Disabled}
                sendCouponDataToExtension={sendCouponDataToExtension}
              />
            ))}
          </Grid.Column>
        )}
      </Grid>
    </div>
  );
};

export default KeywordTargets;
