import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { objectKeysToLowerCase } from '../../../helpers';

import { Search as SearchUI, Grid } from 'semantic-ui-react';

import KeywordSearchResult from './KeywordSearchResult';

class KeywordSearch extends Component {
  state = {
    isLoading: false,
    keywords: [],
  };

  // react complains about props that start with an uppercase
  // caused by passing results to SearchUI
  objectKeysToLowerCase = object => {
    let newObject = {};
    for (let key in object) {
      newObject[key.toLowerCase()] = object[key];
    }
    newObject.key = object.ID;
    newObject.resourceid = object.ID;
    newObject.title = '';
    newObject.description = '';
    return newObject;
  };

  search = async query => {
    const { searchKeywords } = this.props;
    this.setState({ isLoading: true });
    try {
      const keywords = await searchKeywords(query);
      this.setState({
        keywords,
        isLoading: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  debounceSearch = _.debounce(this.search, 300);

  handleChange = (e, data) => {
    const { handleChange } = this.props;
    const { value } = data;
    handleChange(value);
    if (value.length > 2) {
      this.debounceSearch(value);
    }
    if (value.length === 2) {
      this.setState({ keywords: [] });
    }
  };

  // params because we are passing to semantic ui Search
  handleResultSelect = (e, data) => {
    const { handleChange } = this.props;
    handleChange(data.result.value);
  };

  render() {
    const { isLoading, keywords } = this.state;
    const { keywordName } = this.props;
    const formattedKeywords = keywords.map(i => objectKeysToLowerCase(i));

    return (
      <Grid>
        <Grid.Column>
          <SearchUI
            fluid
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={this.handleChange}
            results={formattedKeywords}
            value={keywordName}
            resultRenderer={KeywordSearchResult}
            icon={false}
            input={{
              fluid: true,
              label: 'Name',
            }}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

KeywordSearch.propTypes = {
  searchKeywords: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  keywordName: PropTypes.string.isRequired,
};

export default KeywordSearch;
