import React from 'react';
import { Tab } from 'semantic-ui-react';
import CreateReconcileForm from 'components/Reconciliation/CreateReconcileForm';
import GetReconcileJobs from 'components/Reconciliation/GetReconcileJobs';

const Reconciliation = ({ createReconcileJob, getReconcileJobs }) => {
  const options = [
    {
      menuItem: 'View Reconcile Jobs',
      render: () => (
        <Tab.Pane>
          <GetReconcileJobs getReconcileJobs={getReconcileJobs} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Create a New Reconcile Job',
      render: () => (
        <Tab.Pane>
          <CreateReconcileForm createReconcileJob={createReconcileJob} />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <div style={{ flexGrow: 1, maxWidth: '1200px' }}>
      <h1 style={{ textAlign: 'center' }}>Reconciliation</h1>
      <Tab panes={options} />
    </div>
  );
};

export default Reconciliation;
