import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Input, Button } from 'semantic-ui-react';
import SearchRecipesList from '../../components/CommissionSearch/SearchRecipesList';
import { searchCommissions } from 'actions/commissionSearch/commissionSearch';
import CommissionSearchTable from '../../components/CommissionSearch/CommissionSearchTable';
import SearchGlossary from '../../components/CommissionSearch/SearchGlossary';
import { history } from '../../store';

import { getFollowingDay } from './constants';

interface CommissionSearchProps {
  searchCommissions: (searchTerm: string, pageSize: number) => Promise<any>;
}

const pageSizeOptions = [
  {
    key: '50',
    value: 50,
    text: '50',
  },
  {
    key: '100',
    value: 100,
    text: '100',
  },
  {
    key: '200',
    value: 200,
    text: '200',
  },
];

export const CommissionSearch = (props: CommissionSearchProps) => {
  const { searchCommissions } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const querySearchTerm = urlParams.get('search');
  const queryPageSize = urlParams.get('pagesize');
  const [commissions, setCommissions] = useState([]);
  const [searchValue, setSearchValue] = useState(querySearchTerm || '');
  const [pageSize, updatePageSize] = useState(Number(queryPageSize) || 50);
  const [hasNoSearchResults, setHasNoSearchResults] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [isGlossaryVisible, setIsGlossaryVisible] = useState(false);
  const [inputElement, setInputElement] = useState<Input | null>(null);
  const [isCommissionLoading, setIsCommissionLoading] = useState(false);

  const handleChange = (e: React.FormEvent<HTMLInputElement>, { value }: { value: string }) => {
    setSearchValue(value);
  };

  const fetchCommissions = async () => {
    try {
      setHasNoSearchResults(false);
      setIsCommissionLoading(true);
      setIsAdvancedSearchOpen(false);

      let sanitizedSearchValue = searchValue;
      const eventDateRegex = /EventDate:"(.*?)"/;
      const match = searchValue.match(eventDateRegex);
      if (match) {
        const eventDate = match[1];
        const dayAfter = getFollowingDay(eventDate);
        sanitizedSearchValue = searchValue.replace(
          eventDateRegex,
          `EventDate:>"${eventDate}" EventDate:<"${dayAfter}"`,
        );
      }
      urlParams.set('search', searchValue);
      urlParams.set('pagesize', pageSize.toString());
      history.push({ search: urlParams.toString() });

      const response = await searchCommissions(sanitizedSearchValue, pageSize);

      if (!response.Commissions) {
        setCommissions([]);
        setHasNoSearchResults(true);
      } else {
        setCommissions(response.Commissions);
      }
      setIsCommissionLoading(false);
    } catch (error) {
      console.error(error);
      setCommissions([]);
      setHasNoSearchResults(false);
    }
  };

  useEffect(() => {
    if (querySearchTerm) fetchCommissions();
  }, []); // should only ever run once, upon the first render of the component

  return (
    <div style={{ flexGrow: 1, maxWidth: '95vw' }}>
      <h1 style={{ textAlign: 'center' }}>Commission Search</h1>
      <Form style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flexGrow: 1, marginRight: '1rem' }}>
          <Input
            fluid
            onChange={handleChange}
            placeholder="Search..."
            name="commission-search"
            value={searchValue}
            ref={ref => ref && setInputElement(ref)}
          />
        </div>
        <Button onClick={() => setSearchValue('')} type="button">
          Clear
        </Button>
        <Button onClick={fetchCommissions} primary type="submit">
          Submit
        </Button>
      </Form>

      <h3
        onClick={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
        style={{ cursor: 'pointer', marginTop: '15px' }}
      >
        Advanced Search
        <Icon name={isAdvancedSearchOpen ? 'angle up' : 'angle down'} />
      </h3>

      <div style={isAdvancedSearchOpen ? undefined : { display: 'none' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Dropdown
            selection
            text={`Page Size - ${pageSize}`}
            name="page-size"
            options={pageSizeOptions}
            onChange={(e, { value }) => updatePageSize(value as number)}
          />
          <Form.Button onClick={() => setIsGlossaryVisible(!isGlossaryVisible)} tpe="button">
            {isGlossaryVisible ? 'Hide' : 'Show'} Search Glossary
          </Form.Button>
        </div>
        {isGlossaryVisible && <SearchGlossary />}
        <SearchRecipesList input={inputElement} setSearchValue={setSearchValue} searchValue={searchValue} />
      </div>

      <div style={{ paddingTop: '25px', maxWidth: '100%', overflow: 'auto' }}>
        <CommissionSearchTable commissions={commissions} isCommissionsLoading={isCommissionLoading} />
      </div>
      {hasNoSearchResults && <h3 style={{ textAlign: 'center' }}>No Commissions found for your search.</h3>}
    </div>
  );
};

const mapActionsToProps = {
  searchCommissions,
};

export default connect(null, mapActionsToProps)(CommissionSearch);
