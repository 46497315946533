import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { checkIsWildfireAdmin } from 'helpers/auth0';
import NetworkDashboardContainer from '../../components/NetworkDashboard/NetworkDashboardContainer';
import MerchantStatsContainer from 'pages/MerchantStats/MerchantStatsContainer';
import { useSelector } from 'react-redux';
import { featureFlagSelector } from 'selectors/featureFlags';
import './Home.scss';

const Home: React.FC = () => {
  const { user } = useAuth0();
  const isWildfireAdmin = checkIsWildfireAdmin(user);
  const testFlag = useSelector(state => featureFlagSelector(state, 'test').enabled);

  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleJSONConvert = () => {
    ReactGA.event('json_conversion_click', {
      action: 'click',
      userId: user?.sub || 'Not provided',
      page: 'JSON conversion Page',
    });
    setShouldRedirect(true);
  };

  if (shouldRedirect) {
    return <Redirect to={'/json-conversion-tool'} />;
  }

  return (
    <div className="home">
      <h1 className={testFlag ? "title text-red-500" : "title"} >Welcome to the Wildlink Admin tool!</h1>
      <div className="sections-container">
        <div className="json-converter section-container">
          <div className="section">
            <h3 className="section-title">JSON Conversion Tool</h3>
            <div className="button-container">
              <Button onClick={handleJSONConvert}>CONVERT</Button>
            </div>
          </div>
        </div>
        {isWildfireAdmin && (
          <div className="section-container">
            <div className="section">
              <h3 className="section-title">Merchant Stats</h3>
              <MerchantStatsContainer />
            </div>
          </div>
        )}
      </div>
      <div className="sections-container">
        <div className="network-dashboard section-container">
          <div className="section">
            <NetworkDashboardContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
