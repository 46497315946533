import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Label, Modal, Button } from 'semantic-ui-react';

import './DisabledLabel.css';

class DisabledLabel extends Component {
  state = {
    isLoading: false,
    hasError: false,
    showModal: false,
  };

  toggleDisabled = async () => {
    this.setState({ isLoading: true });
    const { isDisabled, handleResourceChange, locks } = this.props;
    const toggledIsDisabled = !isDisabled;
    const update = {
      Disabled: toggledIsDisabled,
    };
    if (locks) {
      update.DisabledReason = toggledIsDisabled ? 'BY_ADMIN' : 'UNSPECIFIED';
      // auto lock if disabling
      if (toggledIsDisabled) {
        update.Locks = [...locks, 'Disabled'];
      }
    }
    try {
      await handleResourceChange(update);
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({
        isLoading: false,
        hasError: true,
      });
    }
    this.setState({ showModal: false });
  };

  render() {
    const { isLoading } = this.state;
    const { isDisabled, children, uneditable } = this.props;

    if (typeof isDisabled === 'undefined') {
      return null;
    }

    if (uneditable) {
      return (
        <div className="disabled-label">
          <Label color={isDisabled ? 'red' : 'green'} basic={isLoading} attached="top right">
            <span className="toggle uneditable">{isDisabled ? 'Disabled' : 'Enabled'}</span>{' '}
          </Label>
        </div>
      );
    }

    return (
      <div className="disabled-label">
        <Label color={isDisabled ? 'red' : 'green'} basic={isLoading} attached="top right">
          <Modal
            onClose={() => this.setState({ showModal: false })}
            onOpen={() => this.setState({ showModal: true })}
            open={this.state.showModal}
            size="tiny"
            trigger={<span className="toggle">{isDisabled ? 'Disabled' : 'Enabled'}</span>}
            centered={true}
            className="status-modal"
          >
            <Modal.Header>{this.props.titleLabel} Status Change</Modal.Header>
            <Modal.Content>
              <p className="status-modal-message">
                Are you sure you want to {isDisabled ? 'enable' : 'disable'} this {this.props.titleLabel}?
              </p>
              <div className="modal-buttons-container">
                <Button className="disabled-button" color="red" onClick={() => this.setState({ showModal: false })}>
                  Cancel
                </Button>
                <Button className="disabled-button" color="green" onClick={this.toggleDisabled}>
                  Confirm
                </Button>
              </div>
            </Modal.Content>
          </Modal>
          {children}
        </Label>
      </div>
    );
  }
}

DisabledLabel.propTypes = {
  isDisabled: PropTypes.bool,
  handleResourceChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  locks: PropTypes.arrayOf(PropTypes.string),
  uneditable: PropTypes.bool,
};

export default DisabledLabel;
