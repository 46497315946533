import { connect } from 'react-redux';

import { getReconcileJobs, createReconcileJob } from '../../actions/reconciles/reconciles';

import Reconciliation from './Reconciliation';

const mapActionsToProps = {
  getReconcileJobs,
  createReconcileJob,
};

export default connect(null, mapActionsToProps)(Reconciliation);
