import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { request } from 'helpers/api';
import { getDefaultDateInterval } from './helpers/index';
import { groupByOptions, dateIntervalOptions } from '../../pages/SalesDashboardV2/Constants';
import { isValidGroupByCategory } from '../../pages/SalesDashboardV2/helpers';
import Dropdown from '../Dropdown/Dropdown';
import {
  SalesDashDropdownOption,
  SalesDashFormState,
  SalesDashFilterOption,
  DropdownChangeEvent,
  Merchant,
  SearchResult,
} from './types';

interface SalesDashFormProps {
  formState: SalesDashFormState;
  getFilterOptions: (category: string) => Promise<SalesDashFilterOption[]>;
  setError: (error: string) => void;
  setFormState: (formState: SalesDashFormState) => void;
  getMerchant: (merchantId: number) => Promise<Merchant>;
}

const SalesDashForm = ({ formState, getFilterOptions, setError, setFormState, getMerchant }: SalesDashFormProps) => {
  const [formFieldState, setFormFieldState] = useState<SalesDashFormState>(formState);
  const [appOptions, setAppOptions] = useState<SalesDashDropdownOption[]>([]);
  const [merchantOptions, setMerchantOptions] = useState<SalesDashDropdownOption[]>([]);
  const [networkOptions, setNetworkOptions] = useState<SalesDashDropdownOption[]>([]);
  const [isMerchantSearchLoading, setIsMerchantSearchLoading] = useState(false);

  const {
    startDate,
    endDate,
    groupBy,
    dateTrunc,
    appsToInclude,
    merchantsToInclude,
    networksToInclude,
    appsToExclude,
    merchantsToExclude,
    networksToExclude,
  } = formFieldState;

  const today = new Date();
  const dateToday = new Intl.DateTimeFormat('fr-CA').format(today);

  const getFilterItems = async (category: string) => {
    let filterItems: SalesDashFilterOption[] = [];
    try {
      filterItems = await getFilterOptions(category);
      setError('');
    } catch (error) {
      if (typeof error === 'string') {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }

    const dropdownOptions: SalesDashDropdownOption[] = filterItems.map(application => {
      return {
        key: application.ID,
        text: `${application.ID} - ${application.Name}`,
        name: application.Name,
        value: application.ID,
      };
    });

    switch (category) {
      case 'application':
        setAppOptions(dropdownOptions);
        break;
      case 'network':
        setNetworkOptions(dropdownOptions);
        break;
    }
  };

  const handleDropdownChange = (e: React.SyntheticEvent<HTMLElement, Event>, { value, name }: DropdownChangeEvent) => {
    setFormFieldState({ ...formFieldState, [name]: value });
  };

  const search = async (query: string) => {
    setIsMerchantSearchLoading(true);
    try {
      const searchResponse = await request('GET', `/api/search?q=${encodeURIComponent(query)}`);
      const searchResults: SearchResult[] = await searchResponse.json();
      const merchantSearchResults = searchResults.filter(result => result.Type === 'merchant');
      const merchantSearchOptions = merchantSearchResults.map(result => ({
        key: result.ID,
        text: `${result.ID} - ${result.Value}`,
        name: result.Value,
        value: result.ID,
      }));
      setMerchantOptions(merchantSearchOptions);
    } catch (err) {
      if (typeof err === 'string') {
        setError(err);
      } else if (err instanceof Error) {
        setError(err.message || 'Unknown error has occurred.');
      } else {
        setError('Unknown error has occurred.');
      }
    }
    setIsMerchantSearchLoading(false);
  };

  const debouncedSearch = debounce(search, 300);

  const handleMerchantSearch = (searchQuery: string) => {
    setMerchantOptions([]);
    if (searchQuery.length > 2) debouncedSearch(searchQuery);
  };

  const getMerchantOptionData = async (merchantId: number) => {
    const merchant = await getMerchant(merchantId);
    return {
      key: merchant.ID,
      text: `${merchant.ID} - ${merchant.Name}`,
      name: merchant.Name,
      value: merchant.ID,
    };
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Set the date interval to default only if the interval was not changed by the user
    // AND if either new start date or end date was given
    const newState = {
      ...formState,
      startDate: startDate,
      endDate: endDate,
      groupBy: groupBy,
      dateTrunc:
        dateTrunc === formState.dateTrunc && (startDate !== formState.startDate || endDate !== formState.endDate)
          ? getDefaultDateInterval(startDate, endDate)
          : dateTrunc,
      appsToInclude,
      merchantsToInclude,
      networksToInclude,
      appsToExclude,
      merchantsToExclude,
      networksToExclude,
    };
    setFormState(newState);
    setFormFieldState(newState);
  };

  useEffect(() => {
    getFilterItems('application');
    getFilterItems('merchant');
    getFilterItems('network');
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          label="Start Date"
          name="startDate"
          type="date"
          min="2017-07-01"
          max={dateToday}
          value={startDate}
          onChange={e => setFormFieldState({ ...formFieldState, startDate: e.target.value })}
        />
        <Form.Input
          label="End Date"
          name="endDate"
          type="date"
          min="2017-07-01"
          max={dateToday}
          value={endDate}
          onChange={e => setFormFieldState({ ...formFieldState, endDate: e.target.value })}
        />
        <Form.Select
          label="Group By"
          name="groupBy"
          value={groupBy}
          options={groupByOptions}
          onChange={(e, { value }) => {
            if (typeof value === 'string') {
              if (isValidGroupByCategory(value)) {
                setFormFieldState({ ...formFieldState, groupBy: value });
              }
            }
          }}
        />
        <Form.Select
          label="Date Interval"
          name="dateTrunc"
          value={dateTrunc}
          options={dateIntervalOptions}
          onChange={(e, { value }) => {
            if (typeof value === 'string') {
              setFormFieldState({ ...formFieldState, dateTrunc: value });
            }
          }}
        />
      </Form.Group>
      <div className="categoryFiltersContainer equal width fields">
        <div className="categoryFilters">
          <h3 className="filterLabels">Application</h3>
          <Form.Group>
            <Dropdown
              name="appsToInclude"
              value={appsToInclude}
              placeholder="Applications to include"
              options={appOptions}
              disabled={Boolean(appsToExclude.length)}
              loading={Boolean(!appOptions.length)}
              handleChange={handleDropdownChange}
            />
            <Dropdown
              name="appsToExclude"
              value={appsToExclude}
              placeholder="Applications to exclude"
              options={appOptions}
              disabled={Boolean(appsToInclude.length)}
              loading={Boolean(!appOptions.length)}
              handleChange={handleDropdownChange}
            />
          </Form.Group>
        </div>
        <div className="categoryFilters">
          <h3 className="filterLabels">Merchant</h3>
          <Form.Group>
            <Dropdown
              name="merchantsToInclude"
              value={merchantsToInclude}
              placeholder="Merchants to include"
              options={merchantOptions}
              loading={isMerchantSearchLoading}
              disabled={Boolean(merchantsToExclude.length)}
              handleChange={handleDropdownChange}
              handleSearchChange={handleMerchantSearch}
              getOptionData={getMerchantOptionData}
            />
            <Dropdown
              name="merchantsToExclude"
              value={merchantsToExclude}
              placeholder="Merchants to exclude"
              options={merchantOptions}
              loading={isMerchantSearchLoading}
              disabled={Boolean(merchantsToInclude.length)}
              handleChange={handleDropdownChange}
              handleSearchChange={handleMerchantSearch}
              getOptionData={getMerchantOptionData}
            />
          </Form.Group>
        </div>
        <div className="categoryFilters">
          <h3 className="filterLabels">Network</h3>
          <Form.Group>
            <Dropdown
              name="networksToInclude"
              value={networksToInclude}
              placeholder="Networks to include"
              options={networkOptions}
              disabled={Boolean(networksToExclude.length)}
              loading={Boolean(!networkOptions.length)}
              handleChange={handleDropdownChange}
            />
            <Dropdown
              name="networksToExclude"
              value={networksToExclude}
              placeholder="Networks to exclude"
              options={networkOptions}
              disabled={Boolean(networksToInclude.length)}
              loading={Boolean(!networkOptions.length)}
              handleChange={handleDropdownChange}
            />
          </Form.Group>
        </div>
      </div>
      <div className="submitBtnContainer">
        <Button type="submit" id="submitBtn">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default SalesDashForm;
