import { orderBy, isEqual } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import StatsTableHeader from './StatsTableHeader';
import StatsTableDownload from './StatsTableDownload';

const StatsTable = ({
  tableStats,
  timeLabel,
  tableLabel,
  isNonTimeGroupedView,
  setChartMetric,
  chartMetric,
  chartType,
  startDate,
  endDate,
  getDate,
  nameFromStorage,
}) => {
  const [tableData, setTableData] = useState(tableStats);
  const [sortBy, setSortBy] = useState(tableLabel === 'merchant' ? 'GrossCommissionAmount' : 'ReportDate');
  const [sortOrder, setSortOrder] = useState(tableLabel === 'merchant' ? 'desc' : 'asc');
  const tableHeaders = [
    {
      key: 'TransactionCount',
      text: 'Transaction Count',
      value: 'TransactionCount',
      unit: 'count',
    },
    {
      key: 'PositiveSaleCount',
      text: 'Positive Sale Count',
      value: 'PositiveSaleCount',
      unit: 'count',
    },
    {
      key: 'NegativeSaleCount',
      text: 'Negative Sale Count',
      value: 'NegativeSaleCount',
      unit: 'count',
    },
    {
      key: 'ZeroSaleCount',
      text: 'Zero Sale Count',
      value: 'ZeroSaleCount',
      unit: 'count',
    },
    {
      key: 'GrossSaleAmount',
      text: 'Gross Sales',
      value: 'GrossSaleAmount',
      unit: '$',
    },
    {
      key: 'GrossCommissionAmount',
      text: 'Gross Commissions',
      value: 'GrossCommissionAmount',
      unit: '$',
    },
    {
      key: 'NetSaleAmount',
      text: 'Net Sales',
      value: 'NetSaleAmount',
      unit: '$',
    },
    {
      key: 'NetCommissionAmount',
      text: 'Net Commissions',
      value: 'NetCommissionAmount',
      unit: '$',
    },
    {
      key: 'AvgEffectiveCommission',
      text: 'Avg Effective Commission',
      value: 'AvgEffectiveCommission',
      unit: '%',
    },
    {
      key: 'ClickCount',
      text: 'Clicks',
      value: 'ClickCount',
      unit: 'count',
    },
  ];

  const setSort = useCallback(
    clickedColumn => {
      if (sortBy === clickedColumn) {
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
      } else {
        setSortBy(clickedColumn);
        setSortOrder('asc');
      }
      setChartMetric(chartMetric);
    },
    [chartMetric, sortBy, setSortBy, sortOrder, setSortOrder, setChartMetric],
  );

  useEffect(() => {
    let sortedTable;
    switch (sortBy) {
      case 'Application ID':
      case 'Merchant ID':
      case 'Network ID':
        sortedTable = orderBy(tableData, 'ID', [sortOrder]);
        break;
      case 'ReportDate':
      case 'Name':
        sortedTable = orderBy(tableData, [sortBy], [sortOrder]);
        break;
      default:
        sortedTable = orderBy(
          tableData,
          data => {
            return parseFloat(data[sortBy], 10);
          },
          [sortOrder],
        );
    }
    if (!isEqual(sortedTable, tableData)) setTableData(sortedTable);
  }, [sortBy, sortOrder, tableData]);
  const formatToMoney = number => '$' + number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const capitalizedTableLabel = tableLabel.charAt(0).toUpperCase() + tableLabel.slice(1);

  const bodyRows = tableData?.map(stats => {
    const {
      ReportDate,
      ID,
      TransactionCount,
      PositiveSaleCount,
      NegativeSaleCount,
      ZeroSaleCount,
      GrossSaleAmount,
      GrossCommissionAmount,
      NetSaleAmount,
      NetCommissionAmount,
      AvgEffectiveCommission,
      ClickCount,
    } = stats;

    return (
      <Table.Row key={isNonTimeGroupedView ? ID : ReportDate}>
        <Table.Cell>
          <span className="chart-label-span">{timeLabel}: </span>
          {isNonTimeGroupedView ? ID : ReportDate?.slice(0, 10)}
        </Table.Cell>
        {isNonTimeGroupedView && (
          <>
            <Table.Cell>
              <span className="chart-label-span">{capitalizedTableLabel} Name: </span>
              {nameFromStorage(tableLabel, ID)}
            </Table.Cell>
          </>
        )}
        <Table.Cell>
          <span className="chart-label-span">Transaction Count: </span>
          {TransactionCount}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Positive Sale Count: </span>
          {PositiveSaleCount}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Negative Sale Count: </span>
          {NegativeSaleCount}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Zero Sale Count: </span>
          {ZeroSaleCount}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Gross Sales: </span>
          {formatToMoney(GrossSaleAmount)}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Gross Commissions: </span>
          {formatToMoney(GrossCommissionAmount)}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Net Sales: </span>
          {formatToMoney(NetSaleAmount)}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Net Commissions: </span>
          {formatToMoney(NetCommissionAmount)}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Avg Effective Commission: </span>
          {`${AvgEffectiveCommission.toFixed(2)}%`}
        </Table.Cell>
        <Table.Cell>
          <span className="chart-label-span">Count: </span>
          {ClickCount}
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <div className="sales-dashboard-table-container">
      <h3>Stats by {capitalizedTableLabel}</h3>
      {tableStats?.length ? (
        <>
          <StatsTableDownload
            tableHeaders={tableHeaders}
            tableData={tableData}
            tableLabel={tableLabel}
            isNonTimeGroupedView={isNonTimeGroupedView}
            startDate={startDate}
            endDate={endDate}
            getDate={getDate}
            nameFromStorage={nameFromStorage}
          />
          <Table celled striped className="sales-dashboard-table">
            <StatsTableHeader
              timeLabel={timeLabel}
              isNonTimeGroupedView={isNonTimeGroupedView}
              columns={tableHeaders}
              chartMetric={chartMetric}
              setChartMetric={setChartMetric}
              chartType={chartType}
              sortBy={sortBy}
              sortOrder={sortOrder}
              onSort={setSort}
            />
            <Table.Body>{bodyRows}</Table.Body>
          </Table>
        </>
      ) : (
        <div>There are no stats for the specified parameters.</div>
      )}
    </div>
  );
};

export default StatsTable;
