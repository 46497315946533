import React from 'react';
import { useSelector } from 'react-redux';
import { getUserCanEdit } from 'selectors/user';
import { Form, Modal } from 'semantic-ui-react';
import MerchantSearchContainer from '../Merchant/MerchantSearch/MerchantSearchContainer';
import { Merchant } from './types';
import './NetworkMerchantOnboardingModal.scss';

interface NetworkMerchantOnboardingAddMerchantsProps {
  merchant: Merchant | null;
  merchantID: number;
  merchantError: string;
  handleUpdateMerchant: (merchantID: number) => void;
  setMerchantError: (error: string) => void;
}

const NetworkMerchantOnboardingAddMerchants: React.FC<NetworkMerchantOnboardingAddMerchantsProps> = ({
  merchant,
  merchantID,
  merchantError,
  handleUpdateMerchant,
  setMerchantError,
}) => {
  const canEdit = useSelector(state => getUserCanEdit(state));
  return (
    <Modal
      className="add-merchant"
      onClose={() => setMerchantError('')}
      trigger={
        <Form.Input
          label="MerchantID"
          value={`${merchantID} (${merchant ? merchant.Name : 'NONE'})`}
          disabled={!canEdit}
        />
      }
    >
      <Modal.Header>Search Merchant</Modal.Header>
      <Modal.Content>
        {merchantError ? (
          <>
            <p>An error occurred. Please try again in a few minutes.</p>
            <p>{merchantError}</p>
          </>
        ) : (
          <MerchantSearchContainer handleResultSelect={handleUpdateMerchant} currentMerchant={merchant} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default NetworkMerchantOnboardingAddMerchants;
