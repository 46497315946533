import { connect } from 'react-redux';

import { searchKeywords } from '../../../actions/merchantAdmin';

import KeywordSearch from './KeywordSearch';

const mapActionsToProps = {
  searchKeywords,
};

export default connect(null, mapActionsToProps)(KeywordSearch);
