import { connect } from 'react-redux';

import KeywordTargets from './KeywordTargets';
import {
  getKeywordTargetsByKeywordID,
  addKeywordTargetByKeywordID,
  updateKeywordTargetByID,
} from 'actions/keywordTargets/keywordTargets';

import { getKeywordNetworkMerchants } from 'actions/merchantAdmin/keyword';
import { getCoupons } from 'actions/merchantAdmin/networkMerchant';

const mapActionsToProps = {
  getKeywordNetworkMerchants,
  getCoupons,
  getKeywordTargetsByKeywordID,
  addKeywordTargetByKeywordID,
  updateKeywordTargetByID,
};

export default connect(null, mapActionsToProps)(KeywordTargets);
